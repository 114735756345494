import { AxiosError } from "axios";
import { LoggerHelper } from "core-framework";
import { AuthLogOutAction } from "store";

import store from "store/index";

export function ProxyErrorCallback(error: AxiosError) {
  if (error.response!) {
    //LoggerHelper.LogError("proxyHelper Error", error);
    const statusCode = error.response!.status;
    if (statusCode === 401) {
      //Logout yap
      LoggerHelper.LogWarn(
        "proxyHelper",
        "refresh token ile denendi ve yine unauthorized, çıkış yapılması için action çalıştı"
      );
      store.dispatch<any>(AuthLogOutAction());
    }
  }
}
