export class StringOps {
  /* Değerin sayı olduğunu kontrol eder*/
  static isNumber(value: any): boolean {
    let numberRegexp = /^[0-9]+$/;
    return numberRegexp.test(value);
  }

  /* Değerin karakter olduğunu kontrol eder*/
  static isLetter(value: any): boolean {
    let lettersRegexp = /^[A-Za-z]+$/;
    return lettersRegexp.test(value);
  }

  /* Değerin null veya empty olduğunu kontrol eder*/
  static isNullOrEmpty(value: string): boolean {
    let result = true;
    if (value && value !== null && value.length > 0) {
      result = false;
    }

    return result;
  }
}
