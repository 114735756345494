import { Authenticate, LoggerHelper } from "core-framework";
import { Dispatch } from "redux";
import { dKullaniciResponse,  pKullanicilar } from "services/api";
import {
  AuthActionTypeEnum,
  AuthKullanici,
  AuthRoleEnum,
  IAuthAction,
  IAuthState,
} from "store/types/authType";

export const AuthLoginAction = (username: string, password: string) => {
  return async (dispatch: Dispatch): Promise<IAuthState> => {
    const authResult = await Authenticate.AuthenticateUsernamePassword(
      username,
      password
    );

    if (authResult.loggedIn) {
      //Access Token alındı
      try {
        const pkullanicilar = new pKullanicilar();
        const user: dKullaniciResponse = await pkullanicilar.getByGuid(
          "currentUser"
        );

        const authKullanici: AuthKullanici = {};
        if (user.success) {
          //kullanıcı bilgileri apiden getirildi
          authKullanici.guid = user.body!.guid;
          authKullanici.kullaniciKodu = user.body!.kullaniciKodu;
          authKullanici.tamIsmi = `${user.body!.ad!} ${user.body!.soyad!}`;
          authKullanici.ad = user.body!.ad!;
          authKullanici.soyad = user.body!.soyad!;
          authKullanici.telefon = user.body!.telefon!;
          authKullanici.ePosta = user.body!.ePosta!;
          authKullanici.firmaGuid = user.body!.firmaGuid!;
          authKullanici.firmaUnvani = user.body!.firma!.unvani!;
          authKullanici.rol = getirRoleEnum(user.body!.rol!);
        }

        const result: IAuthState = {
          loggedIn: true,
          kullanici: authKullanici,
          messageCode: user.messageCode,
          message: user.message,
        };
        await dispatch<IAuthAction>({
          type: AuthActionTypeEnum.SignInSuccess,
          payload: result,
        });

        return result;
      } catch (err) {
        //Kullanıcı bilgileri getirilirken hata alındı
        LoggerHelper.LogError("authAction", err);
        const result: IAuthState = {
          loggedIn: false,
          kullanici: undefined,
          messageCode: "ConnectionProblem",
          message: "Api Bağlantı Sorunu",
        };
        await dispatch<IAuthAction>({
          type: AuthActionTypeEnum.SignInError,
          payload: result,
        });

        return result;
      }
    } else {
      //Access Token alınamadı
      const result: IAuthState = {
        loggedIn: false,
        kullanici: {},
        messageCode: authResult.messageCode,
        message: authResult.message,
      };
      await dispatch<IAuthAction>({
        type: AuthActionTypeEnum.SignInError,
        payload: result,
      });

      return result;
    }
  };
};

export const AuthLogOutAction = () => {
  return async (dispatch: Dispatch) => {
    await Authenticate.AuthenticateLogout();

    const result: IAuthState = {
      loggedIn: false,
      kullanici: undefined,
      messageCode: "SuccessLogout",
      message: "Çıkış Yapıldı",
    };

    dispatch<IAuthAction>({
      type: AuthActionTypeEnum.SignOut,
      payload: result,
    });

    return result;
  };
};

const getirRoleEnum = (roleNumber: number): AuthRoleEnum => {
  let result: AuthRoleEnum = AuthRoleEnum.NonMember;
  switch (roleNumber) {
    case 2:
      result = AuthRoleEnum.User;
      break;
    case 6:
      result = AuthRoleEnum.TenantAdmin;
      break;
    case 14:
      result = AuthRoleEnum.SystemAdmin;
      break;
    default:
      break;
  }

  return result;
};
