import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from "@microsoft/signalr";
import { Authenticate, ServiceRouter } from "core-framework";

export class ServiceWebSockect {
  /** Connection sağlanır */
  static getHubConnection(hubName: string): HubConnection {
    const serviceUrl = ServiceRouter.getWebSocketUrl(hubName);

    const connect = new HubConnectionBuilder()
      .withUrl(serviceUrl, {
        accessTokenFactory: () => Authenticate.GetAuthenticationAccessToken(),
        //headers: header.headers, buradan taşımıyor
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([1000, 1000, 5000, 10000]) //1sn:1000 4 kez deneyecek
      .build();

    return connect;
  }
}
