import { FormatHelper } from "core-framework/utils";

declare global {
  interface Number {
    round(digit: number): number;
    toAmountString() : string;
  }
}

/** Yuvarlama işlemi yapar*/
// eslint-disable-next-line no-extend-native
Number.prototype.round = function (digit: number = 0) {
  const num = Number(this);
  var p = Math.pow(10, digit);
  var n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
};

/** Para birimi tipine çevirir */
// eslint-disable-next-line no-extend-native
Number.prototype.toAmountString = function () {
  const num = Number(this);
  return FormatHelper.toMoneyAmountFormat(num);
};

export {};
