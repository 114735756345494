import {
  authInitialState,
  IAuthState,
  IAuthAction,
  AuthActionTypeEnum,
} from "store/types/authType";

const authReducer = (
  state: IAuthState = authInitialState,
  action: IAuthAction
): IAuthState => {
  // switch (action.type) {
  //   case "fetchAuth":
  //     console.log("authReducer TESTFORMDAN geldi. payload", action.payload);
  //     console.log("authReducer TESTFORMDAN geldi. state", state);
  //     var donus = { ...state, deger: action.payload };
  //     console.log("authReducer default geldi. donus", donus);
  //     return donus;
  //   default:
  //     console.log("authReducer default geldi. type", action.type);
  //     console.log("authReducer default geldi. donus", state);
  //     return state;
  // }
  switch (action.type) {
    case AuthActionTypeEnum.SignInSuccess:
    case AuthActionTypeEnum.SignInError:
    case AuthActionTypeEnum.SignOut:
      return action.payload;
    case AuthActionTypeEnum.Init:
      break;
    default:
      break;
  }
  return state;
};

export default authReducer;
