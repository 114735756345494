import { isUuid, empty } from "uuidv4";
import { v4 as uuidv4 } from "uuid";

// https://www.npmjs.com/package/uuidv4
export class UUIDHelper {
  static Generate(): string {
    return uuidv4();
  }

  static IsValid(uuid: string): boolean {
    return isUuid(uuid);
  }

  static GenerateEmpty(): string {
    return empty();
  }
}
