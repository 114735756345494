import { LocalStorageKeyEnum } from "core-framework/base/enums";
import { DatetimeOps } from "../operations";
import { CryptoHelper } from "./cryptoHelper";

interface TermStorage<T> {
  object: T;
  expireSecond: number;
  version: number;
}

const _Version = 1;

export class StorageLocalHelper {
  /** Daha sonra çağrılacak key'e göre value local storage kayıt eder. */
  static SetLocalStorage<T>(
    key: LocalStorageKeyEnum | string,
    value: T,
    encrypt: boolean = true
  ): void {
    const serializedValue = JSON.stringify(value);

    if (encrypt) {
      //şifreleyerek sakla
      const encryptValue = CryptoHelper.EncryptAES(serializedValue);
      localStorage.setItem(key, encryptValue);
    } else {
      //Şifrelemeden sakla
      localStorage.setItem(key, serializedValue);
    }
  }

  /** Daha sonra çağrılacak key'e göre value süreli local storage kayıt eder */
  static SetLocalStorageWithExpireTime<T>(
    key: LocalStorageKeyEnum | string,
    value: T,
    expireTimeSecond,
    encrypt: boolean = true
  ): void {
    var expireSecond = DatetimeOps.GetTimeSecond() + expireTimeSecond;
    const sv: TermStorage<T> = {
      object: value,
      expireSecond: expireSecond,
      version: _Version,
    };
    this.SetLocalStorage<TermStorage<T>>(key, sv, encrypt);
  }

  /** Daha sonra çağrılacak key'e göre value versiyona göre local storage kayıt eder */
  static SetLocalStorageWithVersion<T>(
    key: LocalStorageKeyEnum | string,
    value: T,
    versionNumber,
    encrypt: boolean = true
  ): void {
    const sv: TermStorage<T> = {
      object: value,
      expireSecond: -1,
      version: versionNumber,
    };
    this.SetLocalStorage<TermStorage<T>>(key, sv, encrypt);
  }

  /** Kayıt edilen local stroge veriyi getirir. Bulamadığında null döner. Eğer şifreli saklandıysa decrypt edilmelidir */
  static GetLocalStorage<T>(
    key: LocalStorageKeyEnum | string,
    decrypt: boolean = true
  ): T | null {
    const serializedValue = localStorage.getItem(key);

    if (serializedValue !== null) {
      if (decrypt) {
        //şifreli saklandığı için çözülme işlemi yapılıyor. Descryptt içinde JSON Parse yapar
        return CryptoHelper.DecryptAES<T>(serializedValue);
      } else {
        //şifreli saklanmamış
        return JSON.parse(serializedValue);
      }
    }

    return null;
  }

  /** Kayıt edilen local stroge versiyonuna göre getirir. Bulamadığında veya istenilen versiyon değilse olduğunda null döner */
  static GetLocalStorageWithVersion<T>(
    key: LocalStorageKeyEnum | string,
    versionNumber: number,
    decrypt: boolean = true
  ): T | null {
    //const serializedValue = localStorage.getItem(key);
    const serializedValue = this.GetLocalStorage<TermStorage<T>>(key, decrypt);

    if (serializedValue !== null) {
      if (serializedValue.version !== versionNumber) {
        this.RemoveLocalStroge(key);
      } else {
        return serializedValue.object as T;
      }
    }

    return null;
  }

  /** Kayıt edilen local stroge süresine göre getirir. Bulamadığında veya süresi dolduğunda null döner */
  static GetLocalStorageWithExpireTime<T>(
    key: LocalStorageKeyEnum | string,
    decrypt: boolean = true
  ): T | null {
    //const serializedValue = localStorage.getItem(key);
    const serializedValue = this.GetLocalStorage<TermStorage<T>>(key, decrypt);

    if (serializedValue !== null) {
      if (
        serializedValue.expireSecond < DatetimeOps.GetTimeSecond() ||
        serializedValue.version !== _Version
      ) {
        this.RemoveLocalStroge(key);
      } else {
        return serializedValue.object as T;
      }
    }

    return null;
  }

  static RemoveLocalStroge(key: LocalStorageKeyEnum | string): void {
    localStorage.removeItem(key);
  }
}
