import moment from "moment";
import "moment/locale/tr";

export class DatetimeOps {
  constructor() {
    moment.locale("tr");
    //moment.defaultFormat = "DD.MM.YYYY HH:mm";
  }

  /** Şuan ki zamanın saniye karşılığını verir */
  static GetTimeSecond(): number {
    return Math.round(Date.now() / 1000);
  }

  /** Bugünün tarihi, zaman yok */
  static ClearTime(val: Date): Date {
    return moment(val)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toDate();
  }

  /** Bugünün tarihi, zaman yok */
  static GetDateToday(): Date {
    return moment(new Date())
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toDate();
  }

  /** Yarının tarihi, zaman yok */
  static GetDateTomorrow(): Date {
    return moment(new Date())
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .add(1, "days")
      .toDate();
  }

  /** Yarının tarihi, zaman yok */
  static GetDateYesterday(): Date {
    return moment(new Date())
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .add(-1, "days")
      .toDate();
  }

  /** Yılın ilk günü, zaman yok */
  static GetDateFirstDayOfYear(): Date {
    const year = this.GetDateToday().getFullYear();
    return moment(new Date(year, 0, 1))
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toDate();
  }

  /** Yılıın son günü, zaman yok */
  static GetDateLastDayOfYear(): Date {
    const year = this.GetDateToday().getFullYear();
    return moment(new Date(year, 11, 31))
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toDate();
  }
}
