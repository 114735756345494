import i18next, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";

import { LoggerHelper } from "core-framework";
import * as resources from "./resources";
// import translationTurkce from "./translations/turkce.json";
// import translationEnglish from "./translations/english.json";
/*
Kullanım1:
import { useTranslation } from "react-i18next";
const {t, i18n} = useTranslation(); 

i18n.changeLanguage("en"); globolde değiştirir

Kullanım2:

import { t } from "i18next";
*/

const initializeI18n = (props) => {
  // const dispatch = useDispatch();

  // const auth = useSelector((state: IStoreState) => state.auth);
  LoggerHelper.LogInfo("Initialized I18n");
  const ns = Object.keys(Object.values(resources)[0]);

  const opt: InitOptions = { 
    defaultNS: ns,//modüller
    interpolation: {
      escapeValue: false,
    },
    // resources: {
    //   tr: {
    //     translation: translationTurkce,
    //   },
    //   en: {
    //     translation: translationEnglish,
    //   },
    // },
    resources: {
      ...Object.entries(resources).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
        }),
        {}
      ),
    },
    lng: "tr",
    compatibilityJSON: "v3",
  };

  return i18next.use(initReactI18next).init(opt);
};

export default initializeI18n;
