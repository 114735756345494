import { FormatHelper } from "core-framework/utils";

declare global {
  interface Date {
    toDateFormat(): string;
    toDateTimeFormat(): string;
    toDateTimeApiRequestFormat(): string;
  }
}

/** datetime tipi date olarak stringe çevirir */
// eslint-disable-next-line no-extend-native
Date.prototype.toDateFormat = function () {
  return FormatHelper.toDateFormat(this);
};

/** datetime tipi datetime olarak stringe çevirir */
// eslint-disable-next-line no-extend-native
Date.prototype.toDateTimeFormat = function () {
  return FormatHelper.toDateTimeFormat(this);
};

/** datetime tipi datetime olarak api request için stringe çevirir */
// eslint-disable-next-line no-extend-native
Date.prototype.toDateTimeApiRequestFormat = function () {
  return FormatHelper.toDateTimeApiRequestFormat(this);
};

export {};
