import { IScreenContext, LoggerHelper } from "core-framework";
import { pBildirimSayilari } from ".";

export class TemplateOperations {
  /**login olduğunda web socket bağlantı sağlandığında çağrılır. App.tsx den çağrılır.
   */
  static GetNotificationCount = (kullaniciGuid: string) => {
    const context: IScreenContext = {
      screenName: "SYSCLIENT",
      screenHeight: undefined,
      screenWidth: undefined,
    };

    try {
      const pIstek = new pBildirimSayilari(context);
      pIstek.getByGuid(kullaniciGuid);
    } catch (err) {
      LoggerHelper.LogError(
        "TemplateOperations->GetNotificationCount Errro",
        err
      );
    }
  };
}
