import * as cryptoJS from "crypto-js";

export class CryptoHelper {
  static _SecretKey = "rEactWeCons@3526";

  /** AES ile şifreleme yapar, anahtar sabittir */
  static EncryptAES<T>(data: T): string {
    const ciphertext = cryptoJS.AES.encrypt(
      JSON.stringify(data),
      this._SecretKey
    );
    return ciphertext.toString();
  }

  /** AES ile şifreleme yapar, anahtarı dışardan alır */
  static EncryptAESWithKey<T>(key: string, data: T): string {
    const ciphertext = cryptoJS.AES.encrypt(JSON.stringify(data), key);
    return ciphertext.toString();
  }

  /** AES ile şifrelenmiş veriyi, çözer */
  static DecryptAES<T>(ciphertext: string): T {
    var bytes = cryptoJS.AES.decrypt(ciphertext, this._SecretKey);
    var decryptedData = JSON.parse(bytes.toString(cryptoJS.enc.Utf8));

    return JSON.parse(decryptedData) as T;
  }

  /** AES ile şifrenlenmiş şifreyi key */
  static DecryptAESWithKey<T>(key: string, ciphertext: string): T {
    var bytes = cryptoJS.AES.decrypt(ciphertext, key);
    var decryptedData = JSON.parse(bytes.toString(cryptoJS.enc.Utf8));

    return JSON.parse(decryptedData) as T;
  }
}
