//-----------------------------------------------------------------
//Sat Jan 27 2024 00:02:29 GMT+0300 (GMT+03:00)
//https://focus.api.test.ettasis.com/swagger/v1.0/swagger.json
//
//doc2ts aracılığı ile otomatik olarak oluşturulmuştur
//Bu dosyada yapılacak değişikler yanlış davranışa neden olabilir ve
//kod yeniden oluşturulduğunda kaybolur.
//-----------------------------------------------------------------

export interface BaseApiDto {
	guid?: string;
	silindiMi?: boolean;
}
export interface BaseApiDtoResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: BaseApiDto;
}
export enum BildirimDurumu {
	KayitEdildi = "KayitEdildi", 
	Gonderildi = "Gonderildi", 
	Okundu = "Okundu", 
}
export enum BildirimTipi {
	Bilgilendirme = "Bilgilendirme", 
}
export enum CihazTipi {
	Tanimsiz = "Tanimsiz", 
	Role = "Role", 
}
export enum ClientState {
	Closed = "Closed", 
	Active = "Active", 
	TemporaryClosed = "TemporaryClosed", 
}
export enum Dil {
	TR = "TR", 
	ENG = "ENG", 
}
export enum DosyaKokDizini {
	VERI = "VERI", 
	ARSIV = "ARSIV", 
	COPKUTUSU = "COPKUTUSU", 
	SILINDI = "SILINDI", 
	TEMP = "TEMP", 
	THUMBNAIL = "THUMBNAIL", 
}
export enum EPostaGonderimDurumu {
	KayitEdildi = "KayitEdildi", 
	GonderimeBaslandi = "GonderimeBaslandi", 
	Gonderildi = "Gonderildi", 
	GonderilemediTekrarDenencek = "GonderilemediTekrarDenencek", 
	Gonderilemedi = "Gonderilemedi", 
}
export enum EPostaGonderimYeri {
	Diger = "Diger", 
	YeniKullaniciKaydi = "YeniKullaniciKaydi", 
	Monitorize = "Monitorize", 
}
export enum EPostaServisTipi {
	Smtp = "Smtp", 
}
export enum FormAdimYetkiTipi {
	Ilgisine = "Ilgisine", 
	Bilgisine = "Bilgisine", 
}
export enum FormKontrolGosterimTipi {
	KontrolYok = "KontrolYok", 
	DurumButonlariniGoster = "DurumButonlariniGoster", 
	OnayButonlariniGoster = "OnayButonlariniGoster", 
	AltDurumBilgisiniGoster = "AltDurumBilgisiniGoster", 
}
export enum FormOzellikTipi {
	Sabit = "Sabit", 
	Dinamik = "Dinamik", 
}
export enum FormSemaTipi {
	Dinamik = "Dinamik", 
	Proje = "Proje", 
	ProjeTest = "ProjeTest", 
	ProjeTestZafiyet = "ProjeTestZafiyet", 
	ProjeTestZafiyetCozum = "ProjeTestZafiyetCozum", 
}
export enum HareketTuru {
	Tanimsiz = "Tanimsiz", 
	Baslangic = "Baslangic", 
	Bitis = "Bitis", 
	Durum = "Durum", 
	AltDurum = "AltDurum", 
	Karar = "Karar", 
	Onay = "Onay", 
}
export enum IsAkisIslemForm {
	Tanimsiz = "Tanimsiz", 
	UstForm = "UstForm", 
	AltFormlar = "AltFormlar", 
}
export enum IsAkisIslemKullaniciTipi {
	Sistem = "Sistem", 
	Kullanici = "Kullanici", 
}
export enum IsAkisKosul {
	HareketKontroluYap = "HareketKontroluYap", 
	SonAdimKontroluYap = "SonAdimKontroluYap", 
}
export enum IsAkisKosulTipi {
	OnKosul = "OnKosul", 
	Kosul = "Kosul", 
}
export enum IsAkisTetik {
	Ilerlet = "Ilerlet", 
	BitisHareketineGetir = "BitisHareketineGetir", 
}
export enum IsAkisYetkiTuru {
	YetkiSemasinaGore = "YetkiSemasinaGore", 
	FormuGirenKullanicininAmirine = "FormuGirenKullanicininAmirine", 
	FormuOlusturanKullaniciya = "FormuOlusturanKullaniciya", 
}
export enum Karmasiklik {
	Belirsiz = "Belirsiz", 
	Dusuk = "Dusuk", 
	Orta = "Orta", 
	Yuksek = "Yuksek", 
	Kritik = "Kritik", 
}
export enum Oncelik {
	Belirsiz = "Belirsiz", 
	Dusuk = "Dusuk", 
	Orta = "Orta", 
	Yuksek = "Yuksek", 
	Kritik = "Kritik", 
}
export enum Operator {
	Esittir = "Esittir", 
	Buyuk = "Buyuk", 
	Kucuk = "Kucuk", 
	BuyukEsit = "BuyukEsit", 
	KucukEsit = "KucukEsit", 
	Arasinda = "Arasinda", 
	EsitDegil = "EsitDegil", 
}
export enum ParametreDeger {
	ParamKodu = "ParamKodu", 
	ParamAdi = "ParamAdi", 
	ParamDeger01 = "ParamDeger01", 
	ParamDeger02 = "ParamDeger02", 
	ParamDeger03 = "ParamDeger03", 
	ParamDeger04 = "ParamDeger04", 
	ParamDeger05 = "ParamDeger05", 
	ParamDeger06 = "ParamDeger06", 
	ParamDeger07 = "ParamDeger07", 
	ParamDeger08 = "ParamDeger08", 
	ParamDeger09 = "ParamDeger09", 
	ParamDeger10 = "ParamDeger10", 
	ParamDeger11 = "ParamDeger11", 
	ParamDeger12 = "ParamDeger12", 
	ParamDeger13 = "ParamDeger13", 
	ParamDeger14 = "ParamDeger14", 
	ParamDeger15 = "ParamDeger15", 
}
export enum ParametreKolonTip {
	Yazi = "Yazi", 
	TamSayi = "TamSayi", 
	OndalikSayi = "OndalikSayi", 
	Tarih = "Tarih", 
	Zaman = "Zaman", 
	TarihZaman = "TarihZaman", 
	Bool = "Bool", 
	TanimliDegerler = "TanimliDegerler", 
}
export enum RaporStandarti {
	Diger = "Diger", 
	TSE = "TSE", 
}
export enum RiskDurum {
	Belirsiz = "Belirsiz", 
	Dusuk = "Dusuk", 
	Orta = "Orta", 
	Yuksek = "Yuksek", 
	Kritik = "Kritik", 
}
export enum Role {
	NonMember = "NonMember", 
	User = "User", 
	TenantAdmin = "TenantAdmin", 
	SystemAdmin = "SystemAdmin", 
}
export enum SMSGonderimDurumu {
	KayitEdildi = "KayitEdildi", 
	GonderimeBaslandi = "GonderimeBaslandi", 
	Gonderildi = "Gonderildi", 
	GonderilemediTekrarDenencek = "GonderilemediTekrarDenencek", 
	Gonderilemedi = "Gonderilemedi", 
}
export enum SMSGonderimYeri {
	Diger = "Diger", 
	YeniKullaniciKaydi = "YeniKullaniciKaydi", 
	Monitorize = "Monitorize", 
}
export enum SMSSaglayici {
	NetGSM = "NetGSM", 
}
export enum SMSTipi {
	SMS = "SMS", 
	OTP = "OTP", 
}
export interface StringResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: string;
}
export enum TestKapsami {
	Belirsiz = "Belirsiz", 
	InternetUzerinden = "InternetUzerinden", 
	YerelAg = "YerelAg", 
	WebUygulama = "WebUygulama", 
	MobilUygulama = "MobilUygulama", 
	KablosuzAg = "KablosuzAg", 
}
export enum TestTuru {
	Belirsiz = "Belirsiz", 
	BeyazKutu = "BeyazKutu", 
	GriKutu = "GriKutu", 
	SiyahKutu = "SiyahKutu", 
}
export enum UserState {
	NotConfirmed = "NotConfirmed", 
	Active = "Active", 
	Bloked = "Bloked", 
	System = "System", 
}
export enum YetkiSecimi {
	Yok = "Yok", 
	KullaniciSecimi = "KullaniciSecimi", 
	DepartmanSecimi = "DepartmanSecimi", 
}
export interface dAuthorization {
	tokenType?: string;
	accessToken?: string;
	refreshToken?: string;
	expiresIn?: number;
}
export interface dAuthorizationResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dAuthorization;
}
export interface dBankaHesap {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	bankaKodu?: number;
	iban?: string;
	ad?: string;
	dovizKodu?: string;
	entegrasyonHesapBilgisi?: string;
	sonSorgulamaZamani?: Date;
	hesapBakiyesi?: number;
	hesapBlokeliBakiyesi?: number;
}
export interface dBankaHesapHareketDekont {
	guid?: string;
	silindiMi?: boolean;
	bankaHesapGuid?: string;
	basariliIstek?: boolean;
	servisYaniti?: string;
	dekontNo?: string;
	pdfBase64?: string;
}
export interface dBankaHesapHareketDekontResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dBankaHesapHareketDekont;
}
export interface dBankaHesapHareketDetay {
	guid?: string;
	silindiMi?: boolean;
	hareketDetayNo?: string;
	dekontNo?: string;
	muhasebeTarihi?: Date;
	muhasebeReferansi?: string;
	islemZamani?: Date;
	borcAlacak?: string;
	tutar?: number;
	dovizTipi?: string;
	aciklama?: string;
	valorTarihi?: Date;
	tcknVkn?: string;
	adUnvan?: string;
	iban?: string;
	programKodu?: string;
	kayitDurumu?: string;
	iptalZamani?: Date;
	yuruyenBakiye?: number;
	islemTipi?: string;
	islemKodu?: string;
	islemAciklama?: string;
}
export interface dBankaHesapHareketi {
	guid?: string;
	silindiMi?: boolean;
	basariliIstek?: boolean;
	servisYaniti?: string;
	subeKodu?: number;
	subeAdi?: string;
	iban?: string;
	cariBakiye?: number;
	blokeliBakiye?: number;
	hesapHareketDetaylari?: dBankaHesapHareketDetay[];
	bankaHesap?: dBankaHesap;
}
export interface dBankaHesapHareketiResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dBankaHesapHareketi;
}
export interface dBankaHesapIstek {
	bankaKodu?: number;
	iban?: string;
	ad?: string;
	dovizKodu?: number;
	entegrasyonHesapBilgisi?: string;
}
export interface dBankaHesapListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dBankaHesap[];
}
export interface dBankaHesapResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dBankaHesap;
}
export interface dBildirim {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	kullaniciGuid?: string;
	bildirimTipi?: BildirimTipi;
	baslik?: string;
	icerik?: string;
	bildirimDurumu?: BildirimDurumu;
	gonderimGrupReferansi?: string;
	gonderilmeZamani?: Date;
	okumaZamani?: Date;
	kullaniciKodu?: string;
	kullaniciAdi?: string;
	kullaniciSoyadi?: string;
	kullaniciEPosta?: string;
}
export interface dBildirimIstek {
	kullaniciGuid?: string;
	bildirimTipi?: BildirimTipi;
	baslik?: string;
	icerik?: string;
	okumaZamani?: Date;
}
export interface dBildirimListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dBildirim[];
}
export interface dBildirimResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dBildirim;
}
export interface dCihaz {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	cihazTipi?: CihazTipi;
	marka?: string;
	seriNumarasi?: string;
	firmaGuid?: string;
	firma?: dFirma;
}
export interface dCihazBildirim {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	cihazGuid?: string;
	bildirimAnahtari?: string;
	bildirimDegeri?: string;
	cihazBildirimleriniKaydet?: boolean;
	cihaz?: dCihaz;
}
export interface dCihazBildirimIstek {
	cihazTipi?: CihazTipi;
	marka?: string;
	seriNumarasi?: string;
	bildirimAnahtari?: string;
	bildirimDegeri?: string;
}
export interface dCihazBildirimListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dCihazBildirim[];
}
export interface dCihazBildirimResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dCihazBildirim;
}
export interface dCihazIstek {
	cihazTipi?: CihazTipi;
	marka?: string;
	seriNumarasi?: string;
	firmaGuid?: string;
}
export interface dCihazListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dCihaz[];
}
export interface dCihazResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dCihaz;
}
export interface dClient {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	name?: string;
	description?: string;
	state?: ClientState;
}
export interface dClientListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dClient[];
}
export interface dClientReq {
	name?: string;
	description?: string;
	state?: ClientState;
}
export interface dClientResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dClient;
}
export interface dDosya {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	dosyaSablonGuid?: string;
	dosyaIsmi?: string;
	dosyaUzantisi?: string;
	dosyaBoyutuByte?: number;
	dosyaOrtami?: string;
	dosyaKok?: string;
	dosyaYolu?: string;
	dosyaKlasoru?: string;
	dosyaTamDizini?: string;
	referansGuid?: string;
	referansDegeri?: string;
	arsiveTasinmaTarihi?: Date;
	imhaTarihi?: Date;
	zipliDosya?: boolean;
	aciklama?: string;
	dosyaSablon?: dDosyaSablon;
}
export interface dDosyaIstek {
	referansGuid?: string;
	referansDegeri?: string;
}
export interface dDosyaListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dDosya[];
}
export interface dDosyaResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dDosya;
}
export interface dDosyaSablon {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	sablonKodu?: string;
	dosyaUzantisi?: string;
	dosyaSayisi?: number;
	dosyaMaksMBBoyutu?: number;
	dosyaKokDizini?: DosyaKokDizini;
	imhaGunSayisi?: number;
	arsiveTasinmaGunSayisi?: number;
	aciklama?: string;
}
export interface dDosyaSablonIstek {
	sablonKodu?: string;
	dosyaUzantisi?: string;
	dosyaSayisi?: number;
	dosyaMaksMBBoyutu?: number;
	dosyaKokDizini?: DosyaKokDizini;
	imhaGunSayisi?: number;
	arsiveTasinmaGunSayisi?: number;
	aciklama?: string;
}
export interface dDosyaSablonListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dDosyaSablon[];
}
export interface dDosyaSablonResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dDosyaSablon;
}
export interface dEPostaGonderimiIstek {
	ePostaTasiyiciSablonGuid?: string;
	ePostaServisGuid?: string;
	gonderilecekKullaniciGuid?: string;
	ePostaKonusu?: string;
	ePostaIcerigiBase64?: string;
	gonderenAdresi?: string;
	gonderenIsmi?: string;
	oncelik?: number;
}
export interface dEPostaSablon {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	takmaAdi?: string;
	gonderimYeri?: EPostaGonderimYeri;
	gonderenEPostaServisi?: string;
	topluGonderimSablonu?: boolean;
	tasiyiciSablon?: boolean;
	tasiyiciSablonGuid?: string;
	oncelikSirasi?: number;
	konu?: string;
	icerikBase64?: string;
	gonderenIsmi?: string;
	gonderenAdresi?: string;
}
export interface dEPostaSablonIstek {
	takmaAdi?: string;
	gonderimYeri?: EPostaGonderimYeri;
	gonderenEPostaServisi?: string;
	topluGonderimSablonu?: boolean;
	tasiyiciSablon?: boolean;
	tasiyiciSablonGuid?: string;
	oncelikSirasi?: number;
	konu?: string;
	icerikBase64?: string;
	gonderenIsmi?: string;
	gonderenAdresi?: string;
}
export interface dEPostaSablonListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dEPostaSablon[];
}
export interface dEPostaSablonResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dEPostaSablon;
}
export interface dEPostaServis {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	takmaAdi?: string;
	servisTipi?: EPostaServisTipi;
	host?: string;
	port?: number;
	sslKullanilsin?: boolean;
	kullaniciAdi?: string;
	sifreSifrelenmis?: string;
	varsayilanServis?: boolean;
	topluGonderimYapilabilir?: boolean;
	gonderimYapabilir?: boolean;
}
export interface dEPostaServisIstek {
	takmaAdi?: string;
	servisTipi?: EPostaServisTipi;
	host?: string;
	port?: number;
	sslKullanilsin?: boolean;
	kullaniciAdi?: string;
	varsayilanServis?: boolean;
	topluGonderimYapilabilir?: boolean;
	gonderimYapabilir?: boolean;
	yeniSifre?: string;
}
export interface dEPostaServisListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dEPostaServis[];
}
export interface dEPostaServisResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dEPostaServis;
}
export interface dEkran {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	ekranKodu?: string;
	ekranAdi?: string;
	yetkiSemaGuid?: string;
	yetkiSema?: dYetkiSema;
}
export interface dEkranIstek {
	ekranKodu?: string;
	ekranAdi?: string;
	yetkiSemaGuid?: string;
}
export interface dEkranListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dEkran[];
}
export interface dEkranResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dEkran;
}
export interface dEpostaGonderimi {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	kullaniciGuid?: string;
	sablonGuid?: string;
	servisGuid?: string;
	gonderenAdres?: string;
	gonderenIsim?: string;
	adresTo?: string;
	adresCc?: string;
	adresBcc?: string;
	konu?: string;
	icerikBase64?: string;
	ekli?: boolean;
	oncelikSirasi?: number;
	gonderimBaslangicZamani?: Date;
	gonderimBitisZamani?: Date;
	gonderilenZaman?: Date;
	gonderimDenemeSayisi?: number;
	gonderimDurumu?: EPostaGonderimDurumu;
	gonderimGrupReferansi?: string;
	gonderimDurumAciklama?: string;
}
export interface dEpostaGonderimiListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dEpostaGonderimi[];
}
export interface dEpostaGonderimiResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dEpostaGonderimi;
}
export interface dFirma {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	unvani?: string;
	anaFirmaMi?: boolean;
}
export interface dFirmaIstek {
	unvani?: string;
	anaFirmaMi?: boolean;
}
export interface dFirmaListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dFirma[];
}
export interface dFirmaResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dFirma;
}
export interface dForm {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	semaGuid?: string;
	parentFormGuid?: string;
	formAdi?: string;
	formKodu?: string;
	oncelikGuid?: string;
	kategoriGuid?: string;
	formAdimGuid?: string;
	cozumGuid?: string;
	cozumZamani?: Date;
	sema?: dFormSema;
	kategori?: dIsAkisKategori;
	oncelik?: dIsAkisOncelik;
	cozum?: dIsAkisCozum;
	formAdim?: dFormAdim;
}
export interface dFormAdim {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	formGuid?: string;
	isAkisHareketGuid?: string;
	hareketAltDurumGuid?: string;
	ekranKodu?: string;
	secilenDepartmanGuid?: string;
	secilenKullaniciKodu?: string;
	isAkisHareket?: dIsAkisHareket;
	form?: dForm;
	hareketAltDurum?: dIsAkisHareketAltDurum;
	formAdimYetkileri?: dFormAdimYetki[];
}
export interface dFormAdimListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dFormAdim[];
}
export interface dFormAdimYetki {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	formAdimGuid?: string;
	yetkiTipi?: FormAdimYetkiTipi;
	kullaniciKodu?: string;
	islemZamani?: Date;
	kullaniciYorumu?: string;
	formAdim?: dFormAdim;
}
export interface dFormBilesen {
	guid?: string;
	silindiMi?: boolean;
	form?: dForm;
	atananKullanici?: string;
	bulunduguHareket?: dIsAkisHareket;
	hareketDurum?: dIsAkisHareketDurum;
	hareketAltDurumlar?: dIsAkisHareketAltDurum[];
	hareketOnay?: dIsAkisHareketOnay;
	formCozumleri?: dIsAkisCozum[];
	formKontrolGosterimTipi?: FormKontrolGosterimTipi;
	isAkisHareketleri?: dIsAkisHareket[];
}
export interface dFormBilesenIstek {
	hedefHareketGuid?: string;
	oncelikGuid?: string;
	altDurumGuid?: string;
	atananKullaniciKodu?: string;
	atananDepartmanGuid?: string;
	cozumGuid?: string;
}
export interface dFormBilesenResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dFormBilesen;
}
export interface dFormBilgi {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	formGuid?: string;
	anahtar?: string;
	baslik?: string;
	baslikSira?: number;
	degerTipi?: string;
	deger?: string;
	form?: dForm;
}
export interface dFormBilgiListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dFormBilgi[];
}
export interface dFormListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dForm[];
}
export interface dFormResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dForm;
}
export interface dFormSema {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisSemaGuid?: string;
	semaTipi?: FormSemaTipi;
	ad?: string;
	aciklama?: string;
	varsayilanOncelikGuid?: string;
	onEk?: string;
	basamakSayisi?: number;
	isAkisSema?: dIsAkisSema;
	oncelik?: dIsAkisOncelik;
	formlar?: dForm[];
}
export interface dFormSemaIstek {
	isAkisSemaGuid?: string;
	semaTipi?: FormSemaTipi;
	ad?: string;
	aciklama?: string;
	varsayilanOncelikGuid?: string;
	onEk?: string;
	basamakSayisi?: number;
}
export interface dFormSemaListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dFormSema[];
}
export interface dFormSemaResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dFormSema;
}
export interface dFormTakip {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	formGuid?: string;
	kullaniciKodu?: string;
}
export interface dFormTakipIstek {
	formGuid?: string;
	kullaniciKodu?: string;
}
export interface dFormTakipListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dFormTakip[];
}
export interface dFormTakipResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dFormTakip;
}
export interface dFormYorum {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	formGuid?: string;
	yorum?: string;
}
export interface dFormYorumIstek {
	formGuid?: string;
	yorum?: string;
}
export interface dFormYorumListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dFormYorum[];
}
export interface dFormYorumResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dFormYorum;
}
export interface dIsAkisBildirim {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	isAkisHareketGuid?: string;
	isAkisHareket?: dIsAkisHareket;
}
export interface dIsAkisBildirimIstek {
	guid?: string;
	function?: string;
	isAkisHareketGuid?: string;
}
export interface dIsAkisBildirimListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisBildirim[];
}
export interface dIsAkisBildirimResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisBildirim;
}
export interface dIsAkisCozum {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	ad?: string;
	aciklama?: string;
	gorselSiraNo?: number;
}
export interface dIsAkisCozumIstek {
	ad?: string;
	aciklama?: string;
	gorselSiraNo?: number;
}
export interface dIsAkisCozumListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisCozum[];
}
export interface dIsAkisCozumResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisCozum;
}
export interface dIsAkisFonksiyon {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisHareketGuid?: string;
	assemblyName?: string;
	assemblyNamespace?: string;
	methodName?: string;
	isAkisHareket?: dIsAkisHareket;
}
export interface dIsAkisFonksiyonIstek {
	isAkisHareketGuid?: string;
	assemblyName?: string;
	assemblyNamespace?: string;
	methodName?: string;
}
export interface dIsAkisFonksiyonListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisFonksiyon[];
}
export interface dIsAkisFonksiyonResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisFonksiyon;
}
export interface dIsAkisHareket {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	semaGuid?: string;
	hareketTipGuid?: string;
	kategoriGuid?: string;
	ad?: string;
	aciklama?: string;
	formDuzenlenebilir?: boolean;
	yetkiKullanicilariniTakipciYap?: boolean;
	ekranKodu?: string;
	gorselSiraNo?: number;
	hareketTip?: dIsAkisHareketTip;
	kategori?: dIsAkisKategori;
	fonksiyon?: dIsAkisFonksiyon;
	yetki?: dIsAkisYetki;
	kosullar?: dIsAkisKosul[];
	tetikler?: dIsAkisTetik[];
	bildirimler?: dIsAkisBildirim[];
	hareketBaslangic?: dIsAkisHareketBaslangic;
	hareketBitis?: dIsAkisHareketBitis;
	hareketDurum?: dIsAkisHareketDurum;
	hareketAltDurumlar?: dIsAkisHareketAltDurum[];
	hareketKarar?: dIsAkisHareketKarar;
	hareketOnay?: dIsAkisHareketOnay;
}
export interface dIsAkisHareketAltDurum {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisHareketGuid?: string;
	ad?: string;
	sonrakiHareketGuid?: string;
	gorselSiraNo?: number;
	hareketinIlkDurumuMu?: boolean;
	isAkisHareket?: dIsAkisHareket;
	sonrakiIsAkisHareket?: dIsAkisHareket;
}
export interface dIsAkisHareketAltDurumIstek {
	guid?: string;
	function?: string;
	isAkisHareketGuid?: string;
	ad?: string;
	sonrakiHareketGuid?: string;
	hareketinIlkDurumuMu?: boolean;
	gorselSiraNo?: number;
}
export interface dIsAkisHareketBaslangic {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisHareketGuid?: string;
	sonrakiHareketGuid?: string;
	isAkisHareket?: dIsAkisHareket;
}
export interface dIsAkisHareketBaslangicIstek {
	guid?: string;
	function?: string;
	isAkisHareketGuid?: string;
	sonrakiHareketGuid?: string;
}
export interface dIsAkisHareketBitis {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisHareketGuid?: string;
	isAkisCozumGuid?: string;
	isAkisHareket?: dIsAkisHareket;
}
export interface dIsAkisHareketBitisIstek {
	guid?: string;
	function?: string;
	isAkisHareketGuid?: string;
	isAkisCozumGuid?: string;
}
export interface dIsAkisHareketDurum {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisHareketGuid?: string;
	sonrakiHareketGuid?: string;
	iadeHareketGuid?: string;
	isAkisHareket?: dIsAkisHareket;
}
export interface dIsAkisHareketDurumIstek {
	guid?: string;
	function?: string;
	isAkisHareketGuid?: string;
	sonrakiHareketGuid?: string;
	iadeHareketGuid?: string;
}
export interface dIsAkisHareketIstek {
	semaGuid?: string;
	hareketTipGuid?: string;
	kategoriGuid?: string;
	ad?: string;
	aciklama?: string;
	formDuzenlenebilir?: boolean;
	yetkiKullanicilariniTakipciYap?: boolean;
	ekranKodu?: string;
	gorselSiraNo?: number;
	hareketBaslangicIstek?: dIsAkisHareketBaslangicIstek;
	hareketBitisIstek?: dIsAkisHareketBitisIstek;
	hareketDurumIstek?: dIsAkisHareketDurumIstek;
	hareketAltDurumlarIstek?: dIsAkisHareketAltDurumIstek[];
	hareketKararIstek?: dIsAkisHareketKararIstek;
	hareketOnayIstek?: dIsAkisHareketOnayIstek;
}
export interface dIsAkisHareketKarar {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisHareketGuid?: string;
	basariliHareketGuid?: string;
	basarisizHareketGuid?: string;
	ozellikAnahtari?: string;
	operator?: Operator;
	kararDeger1?: string;
	kararDeger2?: string;
	isAkisHareket?: dIsAkisHareket;
}
export interface dIsAkisHareketKararIstek {
	guid?: string;
	function?: string;
	isAkisHareketGuid?: string;
	basariliHareketGuid?: string;
	basarisizHareketGuid?: string;
	ozellikAnahtari?: string;
	operator?: Operator;
	kararDeger1?: string;
	kararDeger2?: string;
}
export interface dIsAkisHareketListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisHareket[];
}
export interface dIsAkisHareketOnay {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisHareketGuid?: string;
	onayHareketGuid?: string;
	onayAciklamaZorunlu?: boolean;
	retHareketGuid?: string;
	retAciklamaZorunlu?: boolean;
	toplamOnaySayisi?: number;
	isAkisHareket?: dIsAkisHareket;
}
export interface dIsAkisHareketOnayIstek {
	guid?: string;
	function?: string;
	isAkisHareketGuid?: string;
	onayHareketGuid?: string;
	onayAciklamaZorunlu?: boolean;
	retHareketGuid?: string;
	retAciklamaZorunlu?: boolean;
	toplamOnaySayisi?: number;
}
export interface dIsAkisHareketResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisHareket;
}
export interface dIsAkisHareketTip {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	hareketTuru?: HareketTuru;
	ad?: string;
	aciklama?: string;
	isAkisIslemKullaniciTipi?: IsAkisIslemKullaniciTipi;
}
export interface dIsAkisHareketTipIstek {
	hareketTuru?: HareketTuru;
	ad?: string;
	aciklama?: string;
	isAkisIslemKullaniciTipi?: IsAkisIslemKullaniciTipi;
}
export interface dIsAkisHareketTipListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisHareketTip[];
}
export interface dIsAkisHareketTipResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisHareketTip;
}
export interface dIsAkisKategori {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	ad?: string;
	aciklama?: string;
	renkHexKodu?: string;
	gorselSiraNo?: number;
}
export interface dIsAkisKategoriIstek {
	ad?: string;
	aciklama?: string;
	renkHexKodu?: string;
	gorselSiraNo?: number;
}
export interface dIsAkisKategoriListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisKategori[];
}
export interface dIsAkisKategoriResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisKategori;
}
export interface dIsAkisKosul {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisHareketGuid?: string;
	isAkisKosulTipi?: IsAkisKosulTipi;
	isAkisKosul?: IsAkisKosul;
	isAkisIslemForm?: IsAkisIslemForm;
	degerIsAkisHareketGuid?: string;
	deger?: string;
	isAkisHareket?: dIsAkisHareket;
	degerIsAkisHareket?: dIsAkisHareket;
}
export interface dIsAkisKosulIstek {
	guid?: string;
	function?: string;
	isAkisHareketGuid?: string;
	isAkisKosulTipi?: IsAkisKosulTipi;
	isAkisKosul?: IsAkisKosul;
	isAkisIslemForm?: IsAkisIslemForm;
	degerIsAkisHareketGuid?: string;
	deger?: string;
}
export interface dIsAkisKosulListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisKosul[];
}
export interface dIsAkisKosulResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisKosul;
}
export interface dIsAkisKosulTopluIstek {
	kosulDetaylari?: dIsAkisKosulIstek[];
}
export interface dIsAkisOncelik {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	ad?: string;
	aciklama?: string;
	renkHexKodu?: string;
	gorselSiraNo?: number;
}
export interface dIsAkisOncelikIstek {
	ad?: string;
	aciklama?: string;
	renkHexKodu?: string;
	gorselSiraNo?: number;
}
export interface dIsAkisOncelikListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisOncelik[];
}
export interface dIsAkisOncelikResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisOncelik;
}
export interface dIsAkisOzellik {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisHareketGuid?: string;
	ozellikTipi?: FormOzellikTipi;
	anahtar?: string;
	deger?: string;
}
export interface dIsAkisOzellikIstek {
	guid?: string;
	function?: string;
	isAkisHareketGuid?: string;
	ozellikTipi?: FormOzellikTipi;
	anahtar?: string;
	deger?: string;
}
export interface dIsAkisOzellikListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisOzellik[];
}
export interface dIsAkisOzellikResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisOzellik;
}
export interface dIsAkisOzellikTopluIstek {
	ozellikler?: dIsAkisOzellikIstek[];
}
export interface dIsAkisSema {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	ad?: string;
	aciklama?: string;
}
export interface dIsAkisSemaIstek {
	ad?: string;
	aciklama?: string;
}
export interface dIsAkisSemaListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisSema[];
}
export interface dIsAkisSemaResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisSema;
}
export interface dIsAkisTetik {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisHareketGuid?: string;
	isAkisTetik?: IsAkisTetik;
	isAkisIslemForm?: IsAkisIslemForm;
	hedefHareketGuid?: string;
	isAkisHareket?: dIsAkisHareket;
}
export interface dIsAkisTetikIstek {
	guid?: string;
	function?: string;
	isAkisHareketGuid?: string;
	isAkisTetik?: IsAkisTetik;
	isAkisIslemForm?: IsAkisIslemForm;
	hedefHareketGuid?: string;
}
export interface dIsAkisTetikListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisTetik[];
}
export interface dIsAkisTetikResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisTetik;
}
export interface dIsAkisTetikTopluIstek {
	tetikDetaylari?: dIsAkisTetikIstek[];
}
export interface dIsAkisYetki {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	isAkisHareketGuid?: string;
	yetkiSecimi?: YetkiSecimi;
	isAkisYetkiTuru?: IsAkisYetkiTuru;
	isAkisHareket?: dIsAkisHareket;
	yetkiDetaylari?: dIsAkisYetkiDetay[];
}
export interface dIsAkisYetkiDetay {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	isAkisYetkiGuid?: string;
	yetkiSemaKodu?: string;
	isAkisYetki?: dIsAkisYetki;
}
export interface dIsAkisYetkiDetayIstek {
	guid?: string;
	function?: string;
	isAkisYetkiGuid?: string;
	yetkiSemaKodu?: string;
}
export interface dIsAkisYetkiIstek {
	guid?: string;
	function?: string;
	isAkisHareketGuid?: string;
	yetkiSecimi?: YetkiSecimi;
	isAkisYetkiTuru?: IsAkisYetkiTuru;
	yetkiDetayIstekleri?: dIsAkisYetkiDetayIstek[];
}
export interface dIsAkisYetkiListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisYetki[];
}
export interface dIsAkisYetkiResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dIsAkisYetki;
}
export interface dKullanici {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	kullaniciKodu?: string;
	ad?: string;
	soyad?: string;
	ePosta?: string;
	telefon?: string;
	kullaniciDurumu?: UserState;
	rol?: number;
	firmaGuid?: string;
	departmanGuid?: string;
	amirKullaniciGuid?: string;
	parolaDegistirmeZamani?: Date;
	sonErisimZamani?: Date;
	firma?: dFirma;
	departman?: dKullaniciDepartmani;
	amirKullanici?: dKullanici;
}
export interface dKullaniciDepartmani {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	ad?: string;
	aciklama?: string;
}
export interface dKullaniciDepartmaniIstek {
	ad?: string;
	aciklama?: string;
}
export interface dKullaniciDepartmaniListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dKullaniciDepartmani[];
}
export interface dKullaniciDepartmaniResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dKullaniciDepartmani;
}
export interface dKullaniciGrubu {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	ad?: string;
	aciklama?: string;
}
export interface dKullaniciGrubuIstek {
	ad?: string;
	aciklama?: string;
}
export interface dKullaniciGrubuListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dKullaniciGrubu[];
}
export interface dKullaniciGrubuResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dKullaniciGrubu;
}
export interface dKullaniciIstek {
	firmaGuid?: string;
	departmanGuid?: string;
	kullaniciKodu?: string;
	amirKullaniciGuid?: string;
	ad?: string;
	soyad?: string;
	parola?: string;
	telefon?: string;
	ePosta?: string;
	rol?: number;
	kullaniciDurumu?: UserState;
}
export interface dKullaniciListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dKullanici[];
}
export interface dKullaniciResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dKullanici;
}
export interface dKullanicininGrubu {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	kullaniciGrupGuid?: string;
	grupKod?: string;
	grupAd?: string;
	kullaniciGuid?: string;
	kullaniciKodu?: string;
	ad?: string;
	soyad?: string;
	ePosta?: string;
}
export interface dKullanicininGrubuIstek {
	kullaniciGrupGuid?: string;
	kullaniciGuid?: string;
}
export interface dKullanicininGrubuListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dKullanicininGrubu[];
}
export interface dKullanicininGrubuResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dKullanicininGrubu;
}
export interface dMenu {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	ustMenuGuid?: string;
	ekranGuid?: string;
	menuAdi?: string;
	adres?: string;
	simge?: string;
	sira?: number;
	ekran?: dEkran;
	ustMenu?: dMenu;
	altMenuler?: dMenu[];
}
export interface dMenuIstek {
	ustMenuGuid?: string;
	ekranGuid?: string;
	menuAdi?: string;
	adres?: string;
	simge?: string;
	sira?: number;
}
export interface dMenuListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dMenu[];
}
export interface dMenuResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dMenu;
}
export interface dParametreDeger {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	parametreDegerSirasi?: number;
	paramGrupKodu?: string;
	paramFirmaGuid?: string;
	paramKodu?: number;
	paramAdi?: string;
	paramDeger01?: string;
	paramDeger02?: string;
	paramDeger03?: string;
	paramDeger04?: string;
	paramDeger05?: string;
	paramDeger06?: string;
	paramDeger07?: string;
	paramDeger08?: string;
	paramDeger09?: string;
	paramDeger10?: string;
	paramDeger11?: string;
	paramDeger12?: string;
	paramDeger13?: string;
	paramDeger14?: string;
	paramDeger15?: string;
}
export interface dParametreDegerIstek {
	paramGrupKodu?: string;
	paramKodu?: number;
	paramAdi?: string;
	paramDeger01?: string;
	paramDeger02?: string;
	paramDeger03?: string;
	paramDeger04?: string;
	paramDeger05?: string;
	paramDeger06?: string;
	paramDeger07?: string;
	paramDeger08?: string;
	paramDeger09?: string;
	paramDeger10?: string;
	paramDeger11?: string;
	paramDeger12?: string;
	paramDeger13?: string;
	paramDeger14?: string;
	paramDeger15?: string;
}
export interface dParametreDegerListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dParametreDeger[];
}
export interface dParametreDegerResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dParametreDeger;
}
export interface dParametreOnbellekIstek {
	paramGrupKodu?: string;
}
export interface dParametreSablon {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	paramGrupKodu?: string;
	modulKodu?: string;
	paramGrupKoduAdi?: string;
	paramGrupKoduAciklama?: string;
	firmaDegerDuzenleyebilirMi?: boolean;
}
export interface dParametreSablonIstek {
	paramGrupKodu?: string;
	modulKodu?: string;
	paramGrupKoduAdi?: string;
	paramGrupKoduAciklama?: string;
	firmaDegerDuzenleyebilirMi?: boolean;
}
export interface dParametreSablonKolon {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	kolonSirasi?: number;
	paramGrupKodu?: string;
	parametreDeger?: ParametreDeger;
	kolonAdi?: string;
	kolonAciklamasi?: string;
	kolonTipi?: ParametreKolonTip;
	kolonDegerleri?: string;
}
export interface dParametreSablonKolonIstek {
	kolonSirasi?: number;
	paramGrupKodu?: string;
	parametreDeger?: ParametreDeger;
	kolonAdi?: string;
	kolonAciklamasi?: string;
	kolonTipi?: ParametreKolonTip;
	kolonDegerleri?: string;
}
export interface dParametreSablonKolonListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dParametreSablonKolon[];
}
export interface dParametreSablonKolonResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dParametreSablonKolon;
}
export interface dParametreSablonListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dParametreSablon[];
}
export interface dParametreSablonResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dParametreSablon;
}
export interface dProje {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	formGuid?: string;
	raporDili?: Dil;
	baslangicZamani?: Date;
	bitisZamani?: Date;
	riskDurumKodu?: RiskDurum;
	raporStandarti?: RaporStandarti;
	projeYetkilisi?: string;
	guvenlikYetkilisi?: string;
	firmaGuid?: string;
	aciklama?: string;
	form?: dForm;
	projeTestleri?: dProjeTest[];
	firmaUnvani?: string;
}
export interface dProjeIstek {
	raporDili?: Dil;
	projeAdi?: string;
	baslangicZamani?: Date;
	bitisZamani?: Date;
	riskDurumKodu?: RiskDurum;
	raporStandarti?: RaporStandarti;
	projeYetkilisi?: string;
	guvenlikYetkilisi?: string;
	firmaGuid?: string;
	aciklama?: string;
	projeTestleriIstek?: dProjeTestIstek[];
}
export interface dProjeListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dProje[];
}
export interface dProjeResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dProje;
}
export interface dProjeTest {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	projeGuid?: string;
	formGuid?: string;
	testKapsami?: TestKapsami;
	detay?: string;
	aciklama?: string;
	dogrulamaTestiYapilacak?: boolean;
	testTuru?: TestTuru;
	form?: dForm;
	proje?: dProje;
}
export interface dProjeTestIstek {
	guid?: string;
	function?: string;
	projeGuid?: string;
	formGuid?: string;
	testKapsami?: TestKapsami;
	adi?: string;
	detay?: string;
	aciklama?: string;
	dogrulamaTestiYapilacak?: boolean;
	testTuru?: TestTuru;
}
export interface dProjeTestListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dProjeTest[];
}
export interface dProjeTestResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dProjeTest;
}
export interface dProjeZafiyet {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	formGuid?: string;
	testGuid?: string;
	zafiyetGuid?: string;
	kanitDosyaGuid?: string;
	vektor?: string;
	vektorSkoru?: number;
	vektorRiskDurumu?: RiskDurum;
	kanitAciklama?: string;
	etkilenenSistem?: string;
	form?: dForm;
	projeTest?: dProjeTest;
	zafiyet?: dZafiyet;
}
export interface dProjeZafiyetIstek {
	guid?: string;
	function?: string;
	testGuid?: string;
	zafiyetGuid?: string;
	kanitDosyaGuid?: string;
	vektor?: string;
	vektorSkoru?: number;
	vektorRiskDurumu?: RiskDurum;
	kanitAciklama?: string;
	etkilenenSistem?: string;
}
export interface dProjeZafiyetListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dProjeZafiyet[];
}
export interface dProjeZafiyetResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dProjeZafiyet;
}
export interface dSMSGonderimi {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	kullaniciGuid?: string;
	sablonGuid?: string;
	servisGuid?: string;
	smsTipi?: SMSTipi;
	baslik?: string;
	gonderilenTelefonNumarasi?: string;
	icerikBase64?: string;
	servisCevapReferansi?: string;
	gonderimBaslangicZamani?: Date;
	gonderimBitisZamani?: Date;
	tekrarGonderimZamani?: Date;
	gonderilenZaman?: Date;
	gonderimDenemeSayisi?: number;
	gonderimDurumu?: SMSGonderimDurumu;
	gonderimGrupReferansi?: string;
	gonderimDurumAciklamasi?: string;
}
export interface dSMSGonderimiIstek {
	servisGuid?: string;
	kullaniciGuid?: string;
	baslik?: string;
	icerikBase64?: string;
}
export interface dSMSGonderimiListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dSMSGonderimi[];
}
export interface dSMSGonderimiResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dSMSGonderimi;
}
export interface dSMSSablon {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	takmaAdi?: string;
	smsTipi?: SMSTipi;
	gonderimYeri?: SMSGonderimYeri;
	baslik?: string;
	icerikBase64?: string;
}
export interface dSMSSablonIstek {
	takmaAdi?: string;
	smsTipi?: SMSTipi;
	gonderimYeri?: SMSGonderimYeri;
	baslik?: string;
	icerikBase64?: string;
}
export interface dSMSSablonListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dSMSSablon[];
}
export interface dSMSSablonResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dSMSSablon;
}
export interface dSMSServis {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	takmaAdi?: string;
	servisSaglayici?: SMSSaglayici;
	kullaniciAdi?: string;
	sifreSifrelenmis?: string;
	varsayilanServis?: boolean;
	servisUrl?: string;
	varsayilanSMSBasligi?: string;
	gonderimYapabilir?: boolean;
}
export interface dSMSServisIstek {
	takmaAdi?: string;
	servisSaglayici?: SMSSaglayici;
	kullaniciAdi?: string;
	sifreSifrelenmis?: string;
	varsayilanServis?: boolean;
	servisUrl?: string;
	varsayilanSMSBasligi?: string;
}
export interface dSMSServisListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dSMSServis[];
}
export interface dSMSServisResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dSMSServis;
}
export interface dSession {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	clientGuid?: string;
	userGuid?: string;
	userAgent?: string;
	pushNotificationCode?: string;
	logoutDate?: Date;
	client?: dClient;
}
export interface dSessionListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dSession[];
}
export interface dSessionReq {
	pushNotificationCode?: string;
}
export interface dSessionResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dSession;
}
export interface dSistem {
	guid?: string;
	silindiMi?: boolean;
	anahtar?: string;
	deger?: string;
	sira?: number;
}
export interface dSistemListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dSistem[];
}
export interface dTokenReq {
	grantType?: string;
	clientId?: string;
	secretKey?: string;
	refreshToken?: string;
	usernameEmail?: string;
	password?: string;
}
export interface dYetkiSema {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	kod?: string;
	ad?: string;
	aciklama?: string;
	silinemez?: boolean;
}
export interface dYetkiSemaDetay {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	yetkiSemaGuid?: string;
	rol?: Role;
	kullaniciGrupGuid?: string;
	kullaniciDepartmanGuid?: string;
	yetkiSema?: dYetkiSema;
	kullaniciGrubu?: dKullaniciGrubu;
	kullaniciDepartmani?: dKullaniciDepartmani;
}
export interface dYetkiSemaDetayIstek {
	yetkiSemaGuid?: string;
	rol?: Role;
	kullaniciGrupGuid?: string;
	kullaniciDepartmanGuid?: string;
}
export interface dYetkiSemaDetayListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dYetkiSemaDetay[];
}
export interface dYetkiSemaDetayResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dYetkiSemaDetay;
}
export interface dYetkiSemaIstek {
	kod?: string;
	ad?: string;
	aciklama?: string;
	silinemez?: boolean;
}
export interface dYetkiSemaListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dYetkiSema[];
}
export interface dYetkiSemaResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dYetkiSema;
}
export interface dZafiyet {
	guid?: string;
	silindiMi?: boolean;
	kayitYapan?: string;
	kayitZamani?: Date;
	guncellemeYapan?: string;
	guncellemeZamani?: Date;
	cve?: string;
	baslik?: string;
	testKapsami?: TestKapsami;
	raporDili?: Dil;
	aciklama?: string;
	vektor?: string;
	vektorSkoru?: number;
	vektorRiskDurumu?: RiskDurum;
	iyilestirmeOnerisi?: string;
	iyilestirmeKarmasikligi?: Karmasiklik;
	iyilestirmeOnceligi?: Oncelik;
	referansAdresi?: string;
}
export interface dZafiyetIstek {
	cve?: string;
	baslik?: string;
	testKapsami?: TestKapsami;
	raporDili?: Dil;
	aciklama?: string;
	vektor?: string;
	vektorSkoru?: number;
	vektorRiskDurumu?: RiskDurum;
	iyilestirmeOnerisi?: string;
	iyilestirmeKarmasikligi?: Karmasiklik;
	iyilestirmeOnceligi?: Oncelik;
	referansAdresi?: string;
}
export interface dZafiyetListResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dZafiyet[];
}
export interface dZafiyetResponse {
	success?: boolean;
	statusCode?: number;
	messageCode?: string;
	message?: string;
	exception?: string;
	body?: dZafiyet;
}
