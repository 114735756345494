export class ValidationHelper {
  // NUMBER
  /* Girilen değerin sayı olduğunu kontrol eder*/
  static isValidateNumber(value: any): boolean {
    let numberRegexp = /^[0-9]+$/;
    return numberRegexp.test(value);
  }

  /* Değerin para birimi olduğunu kontrol eder. Ayraç olarak nokta kullanıyor */
  static isValidateCurrency(value: any): boolean {
    const regex = /^\d+(\.\d{1,2})?$/;
    return regex.test(value);
  }

  /* Değerin tam sayı olduğunu kontrol eder */
  static isValidateInteger(value: any): boolean {
    let numberRegexp = /^[1-9]+[0-9]*$/;
    return numberRegexp.test(value);
  }

  /* Değerin küsaratlı olduğunu kontrol eder*/
  static isValidateFloat(value: any): boolean {
    const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(value)) return false;

    value = parseFloat(value);
    if (isNaN(value)) return false;
    return true;
  }

  //STRING

  /* String değerin E-Posta adresi olduğunu kontrol eder */
  static isValidateEmail(value: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
  }
}
