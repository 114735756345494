declare global {
  interface String {
    decodeBase64(): string;
    encodeBase64(): string;
  }
}

/** Base64 veriyi UTF-8'e göre çözer */
// eslint-disable-next-line no-extend-native
String.prototype.decodeBase64 = function () {
  const str = String(this);
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};

/** Base64 veriyi UTF-8'e göre çözer */
// eslint-disable-next-line no-extend-native
String.prototype.encodeBase64 = function () {
  const str = String(this);
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
};

export {};
