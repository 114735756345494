//-----------------------------------------------------------------
//Sat Jan 27 2024 00:02:29 GMT+0300 (GMT+03:00)
//https://focus.api.test.ettasis.com/swagger/v1.0/swagger.json
//
//doc2ts aracılığı ile otomatik olarak oluşturulmuştur
//Bu dosyada yapılacak değişikler yanlış davranışa neden olabilir ve
//kod yeniden oluşturulduğunda kaybolur.
//-----------------------------------------------------------------

import { IScreenContext, ServiceProxy, Dictionary } from "core-framework";
import * as model from "./models";

import { ProxyErrorCallback } from "./proxyHelper";

export class pBankaHesapHareketDekontlari { 
   public readonly operationName = "MUH/BankaHesapHareketDekontlari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async getByGuid (guid: string, dekontNo: string): Promise<model.dBankaHesapHareketDekontResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("dekontNo", dekontNo); 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback, params); 
   } 

} 

export class pBankaHesapHareketleri { 
   public readonly operationName = "MUH/BankaHesapHareketleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async getByGuid (guid: string, baslangicZamani: Date, bitisZamani: Date): Promise<model.dBankaHesapHareketiResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("baslangicZamani", baslangicZamani.toDateTimeApiRequestFormat()); 
		params.add("bitisZamani", bitisZamani.toDateTimeApiRequestFormat()); 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback, params); 
   } 

} 

export class pBankaHesaplari { 
   public readonly operationName = "MUH/BankaHesaplari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dBankaHesapListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dBankaHesapIstek): Promise<model.dBankaHesapResponse> { 
		return await ServiceProxy.Post<model.dBankaHesapResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dBankaHesapResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dBankaHesapIstek): Promise<model.dBankaHesapResponse> { 
		return await ServiceProxy.Put<model.dBankaHesapResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dBankaHesapResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pBildirimler { 
   public readonly operationName = "BIL/Bildirimler"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(kullaniciGuid: string, bildirimSayisi: number): Promise<model.dBildirimListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("kullaniciGuid", kullaniciGuid); 
		params.add("BildirimSayisi", bildirimSayisi); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dBildirimIstek): Promise<model.dBildirimResponse> { 
		return await ServiceProxy.Post<model.dBildirimResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dBildirimResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dBildirimIstek): Promise<model.dBildirimResponse> { 
		return await ServiceProxy.Put<model.dBildirimResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dBildirimResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pBildirimSayilari { 
   public readonly operationName = "BIL/BildirimSayilari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async getByGuid (guid: string): Promise<model.StringResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pCihazBildirimleri { 
   public readonly operationName = "IOT/CihazBildirimleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(cihazGuid: string): Promise<model.dCihazBildirimListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("cihazGuid", cihazGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dCihazBildirimIstek): Promise<model.dCihazBildirimResponse> { 
		return await ServiceProxy.Post<model.dCihazBildirimResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

} 

export class pCihazlar { 
   public readonly operationName = "IOT/Cihazlar"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dCihazListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async put(guid: string, req?: model.dCihazIstek): Promise<model.dCihazResponse> { 
		return await ServiceProxy.Put<model.dCihazResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

} 

export class pClients { 
   public readonly operationName = "AUT/Clients"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dClientListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async getByGuid (guid: string): Promise<model.dClientResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dClientReq): Promise<model.dClientResponse> { 
		return await ServiceProxy.Put<model.dClientResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

} 

export class pDosyaAktarimlari { 
   public readonly operationName = "DOC/DosyaAktarimlari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async getByGuid (guid: string): Promise<any> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async post(dosyaSablonKodu: string, dosyaKlasoru: string, referansGuid: string, referansDegeri: string, aciklama: string, dosya: any): Promise<model.dDosyaResponse> { 
		const req = new FormData();
		req.append("dosyaSablonKodu", dosyaSablonKodu);
		req.append("dosyaKlasoru", dosyaKlasoru);
		req.append("referansGuid", referansGuid);
		req.append("referansDegeri", referansDegeri);
		req.append("aciklama", aciklama);
		req.append("dosya", dosya);
		return await ServiceProxy.Post<model.dDosyaResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

} 

export class pDosyalar { 
   public readonly operationName = "DOC/Dosyalar"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(dosyaSablonuKodu: string, referansGuid: string, dosyaKlasoru: string): Promise<model.dDosyaListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("dosyaSablonuKodu", dosyaSablonuKodu); 
		params.add("referansGuid", referansGuid); 
		params.add("dosyaKlasoru", dosyaKlasoru); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async getByGuid (guid: string): Promise<model.dDosyaResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dDosyaIstek): Promise<model.dDosyaResponse> { 
		return await ServiceProxy.Put<model.dDosyaResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dDosyaResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pDosyaSablonlari { 
   public readonly operationName = "DOC/DosyaSablonlari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(sablonuKodu: string): Promise<model.dDosyaSablonListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("sablonuKodu", sablonuKodu); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dDosyaSablonIstek): Promise<model.dDosyaSablonResponse> { 
		return await ServiceProxy.Post<model.dDosyaSablonResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dDosyaSablonResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dDosyaSablonIstek): Promise<model.dDosyaSablonResponse> { 
		return await ServiceProxy.Put<model.dDosyaSablonResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

} 

export class pEkranlar { 
   public readonly operationName = "GEN/Ekranlar"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(ekranKodu: string): Promise<model.dEkranListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("ekranKodu", ekranKodu); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dEkranIstek): Promise<model.dEkranResponse> { 
		return await ServiceProxy.Post<model.dEkranResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dEkranResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dEkranIstek): Promise<model.dEkranResponse> { 
		return await ServiceProxy.Put<model.dEkranResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dEkranResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pEPostaGonderimleri { 
   public readonly operationName = "BIL/EPostaGonderimleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(kullaniciGuid: string): Promise<model.dEpostaGonderimiListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("kullaniciGuid", kullaniciGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dEPostaGonderimiIstek): Promise<model.dEpostaGonderimiResponse> { 
		return await ServiceProxy.Post<model.dEpostaGonderimiResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dEpostaGonderimiResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async delete (guid: string): Promise<model.dEpostaGonderimiResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pEPostaSablonlari { 
   public readonly operationName = "BIL/EPostaSablonlari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(tasiyiciSablon: boolean): Promise<model.dEPostaSablonListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("tasiyiciSablon", tasiyiciSablon); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dEPostaSablonIstek): Promise<model.dEPostaSablonResponse> { 
		return await ServiceProxy.Post<model.dEPostaSablonResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dEPostaSablonResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dEPostaSablonIstek): Promise<model.dEPostaSablonResponse> { 
		return await ServiceProxy.Put<model.dEPostaSablonResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dEPostaSablonResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pEPostaServisleri { 
   public readonly operationName = "BIL/EPostaServisleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dEPostaServisListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dEPostaServisIstek): Promise<model.dEPostaServisResponse> { 
		return await ServiceProxy.Post<model.dEPostaServisResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dEPostaServisResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dEPostaServisIstek): Promise<model.dEPostaServisResponse> { 
		return await ServiceProxy.Put<model.dEPostaServisResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dEPostaServisResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pFirmalar { 
   public readonly operationName = "USR/Firmalar"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dFirmaListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dFirmaIstek): Promise<model.dFirmaResponse> { 
		return await ServiceProxy.Post<model.dFirmaResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async put(guid: string, req?: model.dFirmaIstek): Promise<model.dFirmaResponse> { 
		return await ServiceProxy.Put<model.dFirmaResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dFirmaResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pFormAdimlari { 
   public readonly operationName = "FRM/FormAdimlari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(formGuid: string): Promise<model.dFormAdimListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("formGuid", formGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

} 

export class pFormBilesenVerileri { 
   public readonly operationName = "FRM/FormBilesenVerileri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async getByGuid (guid: string): Promise<model.dFormBilesenResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dFormBilesenIstek): Promise<model.dFormBilesenResponse> { 
		return await ServiceProxy.Put<model.dFormBilesenResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

} 

export class pFormBilgileri { 
   public readonly operationName = "FRM/FormBilgileri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(formGuid: string): Promise<model.dFormBilgiListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("formGuid", formGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

} 

export class pFormlar { 
   public readonly operationName = "FRM/Formlar"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(formGuid: string, parentFormGuid: string, formSemaGuid: string, formUygulamaKodu: string, ekranKodu: string, kategoriGuid: string, formKodu: string): Promise<model.dFormListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("formGuid", formGuid); 
		params.add("parentFormGuid", parentFormGuid); 
		params.add("formSemaGuid", formSemaGuid); 
		params.add("formUygulamaKodu", formUygulamaKodu); 
		params.add("ekranKodu", ekranKodu); 
		params.add("kategoriGuid", kategoriGuid); 
		params.add("formKodu", formKodu); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async getByGuid (guid: string): Promise<model.dFormResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pFormSemalari { 
   public readonly operationName = "FRM/FormSemalari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dFormSemaListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dFormSemaIstek): Promise<model.dFormSemaResponse> { 
		return await ServiceProxy.Post<model.dFormSemaResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dFormSemaResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dFormSemaIstek): Promise<model.dFormSemaResponse> { 
		return await ServiceProxy.Put<model.dFormSemaResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dFormSemaResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pFormTakipcileri { 
   public readonly operationName = "FRM/FormTakipcileri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(formGuid: string): Promise<model.dFormTakipListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("formGuid", formGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dFormTakipIstek): Promise<model.dFormTakipResponse> { 
		return await ServiceProxy.Post<model.dFormTakipResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dFormTakipResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async delete (guid: string): Promise<model.dFormTakipResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pFormYorumlari { 
   public readonly operationName = "FRM/FormYorumlari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(formGuid: string): Promise<model.dFormYorumListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("formGuid", formGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dFormYorumIstek): Promise<model.dFormYorumResponse> { 
		return await ServiceProxy.Post<model.dFormYorumResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

} 

export class pIsAkisBildirimler { 
   public readonly operationName = "WFL/IsAkisBildirimler"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dIsAkisBildirimListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisBildirimIstek): Promise<model.dIsAkisBildirimResponse> { 
		return await ServiceProxy.Post<model.dIsAkisBildirimResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

} 

export class pIsAkisCozumleri { 
   public readonly operationName = "WFL/IsAkisCozumleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dIsAkisCozumListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisCozumIstek): Promise<model.dIsAkisCozumResponse> { 
		return await ServiceProxy.Post<model.dIsAkisCozumResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dIsAkisCozumResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dIsAkisCozumIstek): Promise<model.dIsAkisCozumResponse> { 
		return await ServiceProxy.Put<model.dIsAkisCozumResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dIsAkisCozumResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pIsAkisFonksiyonlari { 
   public readonly operationName = "WFL/IsAkisFonksiyonlari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(hareketGuid: string): Promise<model.dIsAkisFonksiyonListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("hareketGuid", hareketGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisFonksiyonIstek): Promise<model.dIsAkisFonksiyonResponse> { 
		return await ServiceProxy.Post<model.dIsAkisFonksiyonResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dIsAkisFonksiyonResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dIsAkisFonksiyonIstek): Promise<model.dIsAkisFonksiyonResponse> { 
		return await ServiceProxy.Put<model.dIsAkisFonksiyonResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dIsAkisFonksiyonResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pIsAkisHareketleri { 
   public readonly operationName = "WFL/IsAkisHareketleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(semaGuid: string): Promise<model.dIsAkisHareketListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("semaGuid", semaGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisHareketIstek): Promise<model.dIsAkisHareketResponse> { 
		return await ServiceProxy.Post<model.dIsAkisHareketResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dIsAkisHareketResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dIsAkisHareketIstek): Promise<model.dIsAkisHareketResponse> { 
		return await ServiceProxy.Put<model.dIsAkisHareketResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dIsAkisHareketResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pIsAkisHareketTipleri { 
   public readonly operationName = "WFL/IsAkisHareketTipleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dIsAkisHareketTipListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisHareketTipIstek): Promise<model.dIsAkisHareketTipResponse> { 
		return await ServiceProxy.Post<model.dIsAkisHareketTipResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dIsAkisHareketTipResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dIsAkisHareketTipIstek): Promise<model.dIsAkisHareketTipResponse> { 
		return await ServiceProxy.Put<model.dIsAkisHareketTipResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

} 

export class pIsAkisKategorileri { 
   public readonly operationName = "WFL/IsAkisKategorileri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dIsAkisKategoriListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisKategoriIstek): Promise<model.dIsAkisKategoriResponse> { 
		return await ServiceProxy.Post<model.dIsAkisKategoriResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dIsAkisKategoriResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dIsAkisKategoriIstek): Promise<model.dIsAkisKategoriResponse> { 
		return await ServiceProxy.Put<model.dIsAkisKategoriResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dIsAkisKategoriResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pIsAkisKosullari { 
   public readonly operationName = "WFL/IsAkisKosullari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(hareketGuid: string): Promise<model.dIsAkisKosulListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("hareketGuid", hareketGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisKosulTopluIstek): Promise<model.dIsAkisKosulResponse> { 
		return await ServiceProxy.Post<model.dIsAkisKosulResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dIsAkisKosulResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pIsAkisOncelikleri { 
   public readonly operationName = "WFL/IsAkisOncelikleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dIsAkisOncelikListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisOncelikIstek): Promise<model.dIsAkisOncelikResponse> { 
		return await ServiceProxy.Post<model.dIsAkisOncelikResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dIsAkisOncelikResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dIsAkisOncelikIstek): Promise<model.dIsAkisOncelikResponse> { 
		return await ServiceProxy.Put<model.dIsAkisOncelikResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dIsAkisOncelikResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pIsAkisOzellikleri { 
   public readonly operationName = "WFL/IsAkisOzellikleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(semaGuid: string, hareketGuid: string): Promise<model.dIsAkisOzellikListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("semaGuid", semaGuid); 
		params.add("hareketGuid", hareketGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisOzellikTopluIstek): Promise<model.dIsAkisOzellikResponse> { 
		return await ServiceProxy.Post<model.dIsAkisOzellikResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dIsAkisOzellikResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pIsAkisSemalari { 
   public readonly operationName = "WFL/IsAkisSemalari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dIsAkisSemaListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisSemaIstek): Promise<model.dIsAkisSemaResponse> { 
		return await ServiceProxy.Post<model.dIsAkisSemaResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dIsAkisSemaResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dIsAkisSemaIstek): Promise<model.dIsAkisSemaResponse> { 
		return await ServiceProxy.Put<model.dIsAkisSemaResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dIsAkisSemaResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pIsAkisTetikleri { 
   public readonly operationName = "WFL/IsAkisTetikleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(hareketGuid: string): Promise<model.dIsAkisTetikListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("hareketGuid", hareketGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisTetikTopluIstek): Promise<model.dIsAkisTetikResponse> { 
		return await ServiceProxy.Post<model.dIsAkisTetikResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dIsAkisTetikResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pIsAkisYetkileri { 
   public readonly operationName = "WFL/IsAkisYetkileri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(hareketGuid: string): Promise<model.dIsAkisYetkiListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("hareketGuid", hareketGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dIsAkisYetkiIstek): Promise<model.dIsAkisYetkiResponse> { 
		return await ServiceProxy.Post<model.dIsAkisYetkiResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dIsAkisYetkiResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pKullaniciDepartmanlari { 
   public readonly operationName = "USR/KullaniciDepartmanlari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dKullaniciDepartmaniListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dKullaniciDepartmaniIstek): Promise<model.dKullaniciDepartmaniResponse> { 
		return await ServiceProxy.Post<model.dKullaniciDepartmaniResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dKullaniciDepartmaniResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dKullaniciDepartmaniIstek): Promise<model.dKullaniciDepartmaniResponse> { 
		return await ServiceProxy.Put<model.dKullaniciDepartmaniResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dKullaniciDepartmaniResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pKullaniciGruplari { 
   public readonly operationName = "USR/KullaniciGruplari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dKullaniciGrubuListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dKullaniciGrubuIstek): Promise<model.dKullaniciGrubuResponse> { 
		return await ServiceProxy.Post<model.dKullaniciGrubuResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dKullaniciGrubuResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dKullaniciGrubuIstek): Promise<model.dKullaniciGrubuResponse> { 
		return await ServiceProxy.Put<model.dKullaniciGrubuResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dKullaniciGrubuResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pKullanicilar { 
   public readonly operationName = "USR/Kullanicilar"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(kullaniciKodu: string, kullaniciYetkiKodu: string, kullaniciYetkiKodlari: string[]): Promise<model.dKullaniciListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("kullaniciKodu", kullaniciKodu); 
		params.add("kullaniciYetkiKodu", kullaniciYetkiKodu); 
		params.add("kullaniciYetkiKodlari", kullaniciYetkiKodlari); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dKullaniciIstek): Promise<model.dKullaniciResponse> { 
		return await ServiceProxy.Post<model.dKullaniciResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dKullaniciResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dKullaniciIstek): Promise<model.dKullaniciResponse> { 
		return await ServiceProxy.Put<model.dKullaniciResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dKullaniciResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pKullanicininGruplari { 
   public readonly operationName = "USR/KullanicininGruplari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(kullaniciGuid: string, kullaniciGrupGuid: string): Promise<model.dKullanicininGrubuListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("kullaniciGuid", kullaniciGuid); 
		params.add("kullaniciGrupGuid", kullaniciGrupGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dKullanicininGrubuIstek): Promise<model.dKullanicininGrubuResponse> { 
		return await ServiceProxy.Post<model.dKullanicininGrubuResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dKullanicininGrubuResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pMenuler { 
   public readonly operationName = "GEN/Menuler"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(kullaniciMenusu: boolean): Promise<model.dMenuListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("kullaniciMenusu", kullaniciMenusu); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dMenuIstek): Promise<model.dMenuResponse> { 
		return await ServiceProxy.Post<model.dMenuResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dMenuResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dMenuIstek): Promise<model.dMenuResponse> { 
		return await ServiceProxy.Put<model.dMenuResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dMenuResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pParametreDegerleri { 
   public readonly operationName = "PAR/ParametreDegerleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(paramGrupKodu: string): Promise<model.dParametreDegerListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("paramGrupKodu", paramGrupKodu); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dParametreDegerIstek): Promise<model.dParametreDegerResponse> { 
		return await ServiceProxy.Post<model.dParametreDegerResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dParametreDegerResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dParametreDegerIstek): Promise<model.dParametreDegerResponse> { 
		return await ServiceProxy.Put<model.dParametreDegerResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dParametreDegerResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pParametreOnbellekleri { 
   public readonly operationName = "PAR/ParametreOnbellekleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async post(req?: model.dParametreOnbellekIstek): Promise<model.BaseApiDtoResponse> { 
		return await ServiceProxy.Post<model.BaseApiDtoResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

} 

export class pParametreSablonKolonlari { 
   public readonly operationName = "PAR/ParametreSablonKolonlari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(grupKodu: string): Promise<model.dParametreSablonKolonListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("grupKodu", grupKodu); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dParametreSablonKolonIstek): Promise<model.dParametreSablonKolonResponse> { 
		return await ServiceProxy.Post<model.dParametreSablonKolonResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dParametreSablonKolonResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dParametreSablonKolonIstek): Promise<model.dParametreSablonKolonResponse> { 
		return await ServiceProxy.Put<model.dParametreSablonKolonResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dParametreSablonKolonResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pParametreSablonlari { 
   public readonly operationName = "PAR/ParametreSablonlari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(modulKodu: string): Promise<model.dParametreSablonListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("modulKodu", modulKodu); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dParametreSablonIstek): Promise<model.dParametreSablonResponse> { 
		return await ServiceProxy.Post<model.dParametreSablonResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dParametreSablonResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dParametreSablonIstek): Promise<model.dParametreSablonResponse> { 
		return await ServiceProxy.Put<model.dParametreSablonResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dParametreSablonResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pProjeler { 
   public readonly operationName = "DFT/Projeler"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(formGuid: string): Promise<model.dProjeListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("formGuid", formGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dProjeIstek): Promise<model.dProjeResponse> { 
		return await ServiceProxy.Post<model.dProjeResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dProjeResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dProjeIstek): Promise<model.dProjeResponse> { 
		return await ServiceProxy.Put<model.dProjeResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dProjeResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pProjeTestleri { 
   public readonly operationName = "DFT/ProjeTestleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(projeGuid: string, formGuid: string, ekranKodu: string, cozumDurumu: string): Promise<model.dProjeTestListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("projeGuid", projeGuid); 
		params.add("formGuid", formGuid); 
		params.add("ekranKodu", ekranKodu); 
		params.add("cozumDurumu", cozumDurumu); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dProjeTestIstek): Promise<model.dProjeTestResponse> { 
		return await ServiceProxy.Post<model.dProjeTestResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dProjeTestResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dProjeTestIstek): Promise<model.dProjeTestResponse> { 
		return await ServiceProxy.Put<model.dProjeTestResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dProjeTestResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pProjeZafiyetleri { 
   public readonly operationName = "DFT/ProjeZafiyetleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(projeTestGuid: string, formGuid: string): Promise<model.dProjeZafiyetListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("projeTestGuid", projeTestGuid); 
		params.add("formGuid", formGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dProjeZafiyetIstek): Promise<model.dProjeZafiyetResponse> { 
		return await ServiceProxy.Post<model.dProjeZafiyetResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dProjeZafiyetResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dProjeZafiyetIstek): Promise<model.dProjeZafiyetResponse> { 
		return await ServiceProxy.Put<model.dProjeZafiyetResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dProjeZafiyetResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pSessions { 
   public readonly operationName = "AUT/Sessions"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(kullaniciGuid: string): Promise<model.dSessionListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("kullaniciGuid", kullaniciGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async put(guid: string, req?: model.dSessionReq): Promise<model.dSessionResponse> { 
		return await ServiceProxy.Put<model.dSessionResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

} 

export class pSistemOzellikleri { 
   public readonly operationName = "GEN/SistemOzellikleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dSistemListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

} 

export class pSMSGonderimleri { 
   public readonly operationName = "BIL/SMSGonderimleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dSMSGonderimiListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dSMSGonderimiIstek): Promise<model.dSMSGonderimiResponse> { 
		return await ServiceProxy.Post<model.dSMSGonderimiResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dSMSGonderimiResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pSMSSablonlari { 
   public readonly operationName = "BIL/SMSSablonlari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dSMSSablonListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dSMSSablonIstek): Promise<model.dSMSSablonResponse> { 
		return await ServiceProxy.Post<model.dSMSSablonResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dSMSSablonResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dSMSSablonIstek): Promise<model.dSMSSablonResponse> { 
		return await ServiceProxy.Put<model.dSMSSablonResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dSMSSablonResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pSMSServisleri { 
   public readonly operationName = "BIL/SMSServisleri"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(): Promise<model.dSMSServisListResponse> { 
		let params = new Dictionary<string, object>(); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dSMSServisIstek): Promise<model.dSMSServisResponse> { 
		return await ServiceProxy.Post<model.dSMSServisResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dSMSServisResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dSMSServisIstek): Promise<model.dSMSServisResponse> { 
		return await ServiceProxy.Put<model.dSMSServisResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dSMSServisResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pTokens { 
   public readonly operationName = "AUT/Tokens"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async delete (guid: string): Promise<model.dAuthorizationResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async getByGuid (guid: string): Promise<model.dAuthorizationResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async post(req?: model.dTokenReq): Promise<model.dAuthorizationResponse> { 
		return await ServiceProxy.Post<model.dAuthorizationResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

} 

export class pYeniKullanicilar { 
   public readonly operationName = "USR/YeniKullanicilar"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async post(req?: model.dKullaniciIstek): Promise<model.dKullaniciResponse> { 
		return await ServiceProxy.Post<model.dKullaniciResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

} 

export class pYetkiSemaDetaylari { 
   public readonly operationName = "USR/YetkiSemaDetaylari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(semaGuid: string): Promise<model.dYetkiSemaDetayListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("semaGuid", semaGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dYetkiSemaDetayIstek): Promise<model.dYetkiSemaDetayResponse> { 
		return await ServiceProxy.Post<model.dYetkiSemaDetayResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dYetkiSemaDetayResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dYetkiSemaDetayIstek): Promise<model.dYetkiSemaDetayResponse> { 
		return await ServiceProxy.Put<model.dYetkiSemaDetayResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dYetkiSemaDetayResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pYetkiSemalari { 
   public readonly operationName = "USR/YetkiSemalari"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(kullaniciGuid: string): Promise<model.dYetkiSemaListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("kullaniciGuid", kullaniciGuid); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dYetkiSemaIstek): Promise<model.dYetkiSemaResponse> { 
		return await ServiceProxy.Post<model.dYetkiSemaResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dYetkiSemaResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dYetkiSemaIstek): Promise<model.dYetkiSemaResponse> { 
		return await ServiceProxy.Put<model.dYetkiSemaResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dYetkiSemaResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

export class pZafiyetler { 
   public readonly operationName = "DFT/Zafiyetler"; 
   private context;

   constructor(context?: IScreenContext) { 
     this.context = context; 
   } 

   async get(testKapsami: string, raporDili: string, filtre: string): Promise<model.dZafiyetListResponse> { 
		let params = new Dictionary<string, object>(); 
		params.add("testKapsami", testKapsami); 
		params.add("raporDili", raporDili); 
		params.add("filtre", filtre); 
		return await ServiceProxy.Get(this.context, this.operationName, ProxyErrorCallback, params); 
   } 

   async post(req?: model.dZafiyetIstek): Promise<model.dZafiyetResponse> { 
		return await ServiceProxy.Post<model.dZafiyetResponse>(this.context, this.operationName, ProxyErrorCallback, req);
   } 

   async getByGuid (guid: string): Promise<model.dZafiyetResponse> { 
		return await ServiceProxy.GetByGuid(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

   async put(guid: string, req?: model.dZafiyetIstek): Promise<model.dZafiyetResponse> { 
		return await ServiceProxy.Put<model.dZafiyetResponse>(this.context, this.operationName, guid, ProxyErrorCallback, req);
   } 

   async delete (guid: string): Promise<model.dZafiyetResponse> { 
		return await ServiceProxy.Delete(this.context, this.operationName, guid, ProxyErrorCallback); 
   } 

} 

