import {
  DatetimeOps,
  LocalStorageKeyEnum,
  StorageLocalHelper,
} from "core-framework";
import { AuthenticateStoreKeyEnum } from "./authenticateEnums";
import { IAuthenticateStoreData } from "./IAuthenticate";

const _StorageAuthVersion = "1";

/** LocalStorage kayıt edilecek IAuthenticateStoreData[] tipinde ki verinindeğerini getirir, public yapılmamalıdır  */
export const SetAuthenticateStoreValue = (
  key: AuthenticateStoreKeyEnum,
  expireTimeSecond: number,
  value: string
) => {
  let ls = StorageLocalHelper.GetLocalStorage<IAuthenticateStoreData[]>(
    LocalStorageKeyEnum.Auth,
    true
  );

  if (ls === null) {
    //Hiç kayıt edilmemiş
    ls = [];

    //Her koşulda eklenmesi gereken versiyon bilgisi
    const versionValue: IAuthenticateStoreData = {
      key: AuthenticateStoreKeyEnum.Version,
      value: _StorageAuthVersion,
      expireTimeSecond: -1,
    };
    ls.push(versionValue);
  } else {
    //Kayıt varsa nesneden çıkarılıyor
    const objectIndex = ls.findIndex((x) => x.key === key);
    if (objectIndex > -1) {
      ls.splice(objectIndex, 1);
    }
  }

  //Yeni obje dolduruyor
  let newObject: IAuthenticateStoreData = {
    key: key,
    value: value,
    expireTimeSecond: expireTimeSecond,
  };
  ls.push(newObject);
  StorageLocalHelper.SetLocalStorage(LocalStorageKeyEnum.Auth, ls, true);
};

/** LocalStorage kayıt edilen IAuthenticateStoreData[] tipinde ki verinindeğerini getirir, public yapılmamalıdır  */
export const GetAuthenticateStoreValue = (
  key: AuthenticateStoreKeyEnum
): string => {
  const ls = StorageLocalHelper.GetLocalStorage<IAuthenticateStoreData[]>(
    LocalStorageKeyEnum.Auth,
    true
  );
  if (ls === null) {
    return "";
  }

  //Versiyon kontrolü yapılıyor, eğer versiyonu tutmaz ise kaldır
  const versionObject = ls.find(
    (x) => x.key === AuthenticateStoreKeyEnum.Version
  );
  if (!versionObject || versionObject.value !== _StorageAuthVersion) {
    RemoveAuthenticateStoreValue();
    return "";
  }

  const responseObject = ls.find((x) => x.key === key);
  if (responseObject) {
    //İstenilen valuenun expire time kontorolünü yapılıyor
    if (
      responseObject.expireTimeSecond > 0 &&
      responseObject.expireTimeSecond < DatetimeOps.GetTimeSecond()
    ) {
      return "";
    } else {
      return responseObject.value;
    }
  }

  return "";
};

export const SetSuccessTokenToStorage = (
  tokenType: string,
  accessToken: string,
  accessTokenExpiresIn: number,
  refreshToken: string
) => {
  SetAuthenticateStoreValue(
    AuthenticateStoreKeyEnum.AccessToken,
    DatetimeOps.GetTimeSecond() + accessTokenExpiresIn,
    tokenType + " " + accessToken
  );
  SetAuthenticateStoreValue(
    AuthenticateStoreKeyEnum.RefreshToken,
    -1,
    refreshToken
  );
  SetAuthenticateStoreValue(
    AuthenticateStoreKeyEnum.LastLoginTimeSecond,
    -1,
    DatetimeOps.GetTimeSecond().toString()
  );
};

/** LocalStorage Authenticate verilerini siler */
export const RemoveAuthenticateStoreValue = () => {
  StorageLocalHelper.RemoveLocalStroge(LocalStorageKeyEnum.Auth);
};
