import { HubConnection } from "@microsoft/signalr";
import { LoggerHelper, ServiceWebSockect } from "core-framework";
import { changeNotificationCountAction } from "store";

export class ProxyWebSocket {
  /**Base Web socket login olduğunda otomatik sağlanır. App.tsx den çağrılır.
   */
  static BaseWebSocket = (dispatch: any) => {
    const connection: HubConnection =
      ServiceWebSockect.getHubConnection("common");

    LoggerHelper.LogInfo("ProxyWebSocket Starting");

    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("Notification", (obj: any) => {
            receiveNotificationMetot(JSON.parse(obj));
          });
        })
        .catch((error) => LoggerHelper.LogError(error));
    }

    //subscribe olunan metotlar
    const receiveNotificationMetot = (obj: any) => {
      LoggerHelper.LogInfo("receiveNotificationMetot", obj);
      dispatch(changeNotificationCountAction(obj.GonderimSayisi));
    };
  };
}

// const sendMessage = async () => {
//   const inputText = "Gönderildi ve geri getirildi";
//   console.log("sendMessage", connection);
//   if (connection) await connection.send("SendMessage", inputText);
// };
