import { ConfigTypeEnum, EnviromentEnum } from "core-framework/base/enums";
import * as dotenv from "dotenv";

//Start ile çalıştığında env hep development oldu
export class ConfigHelper {
  constructor() {
    dotenv.config();
    //dotenv.config({ path: __dirname });
    //const env = process.env.NODE_ENV;
  }
  /** env dosyasında config bilgisini getirir */
  static GetConfig(params: ConfigTypeEnum) {
    let value = process.env[params];
    return value !== undefined ? value : null;
  }

  static GetEnviroment(): EnviromentEnum {
    const env = process.env.NODE_ENV;

    let result = EnviromentEnum.unknown;
    switch (env) {
      case "development":
        result = EnviromentEnum.development;
        break;
      case "test":
        result = EnviromentEnum.test;
        break;
      case "production":
        result = EnviromentEnum.production;
        break;
      default:
        result = EnviromentEnum.unknown;
        break;
    }

    return result;
  }
}
