import { EnviromentEnum } from "core-framework/base/enums";
import { ConfigHelper } from "./configHelper";

/** production ortamında log yazmaz */
export class LoggerHelper {
  static Log(key: string, params?: any): void {
    const env = ConfigHelper.GetEnviroment();
    if (env !== EnviromentEnum.production) console.log(key, params);
  }

  static LogInfo(key: string, params?: any): void {
    const env = ConfigHelper.GetEnviroment();
    let message = `INFO: ${key}`;
    let val = params ?? "";
    if (env !== EnviromentEnum.production) console.log(message, val);
  }

  static LogWarn(key: string, params?: any): void {
    const env = ConfigHelper.GetEnviroment();
    let message = `WARN: ${key}`;
    let val = params ?? "";
    if (env !== EnviromentEnum.production) console.log(message, val);
  }

  static LogError(key: string, params?: any): void {
    const env = ConfigHelper.GetEnviroment();
    let message = `ERROR: ${key}`;
    let val = params ?? "";
    if (env !== EnviromentEnum.production) console.log(message, val);
  }
}
