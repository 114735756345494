import { Dispatch } from "redux";
import { ITemplateAction, templateActionTypeEnum } from "store";

//Diğer sayfalardan burası tetiklenir, dispatch yapılır ve reducere gider. Reducer store edilir
/** Nav menu gösterme/responsive yapma */
export const navMenuChangeAction = (val: any) => {
  return async (dispatch: Dispatch) => {
    dispatch<ITemplateAction>({
      type: templateActionTypeEnum.SideBarShow,
      payload: val,
    });
  };
};

export const changeNotificationCountAction = (val: number) => {
  return async (dispatch: Dispatch) => {
    dispatch<ITemplateAction>({
      type: templateActionTypeEnum.ChangeNotificationCount,
      payload: val,
    });
  };
};
