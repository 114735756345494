import React from "react";
import { useSelector } from "react-redux";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./scss/style.scss";
import { IStoreState } from "store";

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// );
const loading = (
  <div className="loader-wrapper">
    <div className="loader"></div>
  </div>
);
// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
//const Login = React.lazy(() => import("./views/pages/login/Login"));

const AUTTLOGN = React.lazy(() => import("./views/AUT/AUTTLOGN/auttlogn"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

//class App extends Component {
const App = () => {
  const loggedIn = useSelector((state: IStoreState) => state.auth.loggedIn);
  const history = createBrowserHistory();

  return (
    <HashRouter history={history}>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) =>
              loggedIn ? <Redirect to="/" /> : <AUTTLOGN {...props} />
            }
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) =>
              loggedIn ? <TheLayout {...props} /> : <Redirect to="/login" />
            }
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
