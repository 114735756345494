import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import reduxThunk from "redux-thunk";
import {
  ConfigHelper,
  EnviromentEnum,
  LocalStorageKeyEnum,
  StorageLocalHelper,
} from "core-framework";

import { IAuthState } from "./types/authType";
import { ITemplateState } from "./types/templateType";

import authReducer from "./reducers/authReducer";
import { TemplateReducer } from "./reducers/templateReducer";

//https://github.com/piotrwitek/react-redux-typescript-guide/blob/master/playground/src/store/index.ts

const _storageVersion = 1;

export * from "./actions";
export * from "./types";

export interface IStoreState {
  auth: IAuthState;
  template: ITemplateState;
}
export const reducers = combineReducers<IStoreState>({
  auth: authReducer,
  template: TemplateReducer,
});

//Dev ortamda stateler reduxdevtoolda görebilmesini için
const composeEnhancers =
  ((ConfigHelper.GetEnviroment() === EnviromentEnum.development ||
    ConfigHelper.GetEnviroment() === EnviromentEnum.test) &&
    (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose)) ||
  compose;

//middleware her zaman action döner
// const enhancer = composeEnhancers(applyMiddleware(reduxThunk));

// // create store
// const store = createStore(reducers, initialState, enhancer);
const store = createStore(
  reducers,
  StorageLocalHelper.GetLocalStorageWithVersion<IStoreState>(
    LocalStorageKeyEnum.Storage,
    _storageVersion,
    true
  )! ?? undefined,
  composeEnhancers(applyMiddleware(reduxThunk))
);

//State / store değişikliğini dinler
store.subscribe(() => {
  //Şifreli ve versiyona göre saklar. Versiyon değiştiğinde otomatik siler
  StorageLocalHelper.SetLocalStorageWithVersion<IStoreState>(
    LocalStorageKeyEnum.Storage,
    store.getState(),
    _storageVersion,
    true
  );
});

export default store;
