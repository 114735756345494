import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStoreState } from "store";
import { ProxyWebSocket, TemplateOperations } from "services/api";
import { LoggerHelper } from "core-framework";
const ApiWebSocket = (props) => {
  const dispatch = useDispatch();

  const auth = useSelector((state: IStoreState) => state.auth);

  if (auth.loggedIn) {
    ProxyWebSocket.BaseWebSocket(dispatch);

    try {
      TemplateOperations.GetNotificationCount(auth.kullanici!.guid!);
    } catch (err) {
      LoggerHelper.LogError("getNotificationCount is error", err);
    }
  }

  return <React.Fragment></React.Fragment>;
};

export default ApiWebSocket;
