import { ConfigTypeEnum, ServiceProxy } from "core-framework";
import { AuthenticateStoreKeyEnum } from "./authenticateEnums";
import { IAuthenticateResponse } from "./IAuthenticate";
import {
  SetSuccessTokenToStorage,
  GetAuthenticateStoreValue,
  RemoveAuthenticateStoreValue,
} from "./authenticateStore";
import { dTokenReq } from "./authServiceModels";
import { ConfigHelper, StringOps } from "core-framework/utils";

export class Authenticate {
  /** Kullanıcı ad ve şifre ile access token alır */
  static async AuthenticateUsernamePassword(
    usernameEmail: string,
    password: string
  ): Promise<IAuthenticateResponse> {
    const request: dTokenReq = {
      grantType: "password",
      clientId: ConfigHelper.GetConfig(ConfigTypeEnum.ClientId)!, //Uygulama özelinde verilen değer
      secretKey: ConfigHelper.GetConfig(ConfigTypeEnum.ClientSecret)!, //Uygulama özelinde verilen şifre
      refreshToken: "",
      usernameEmail: usernameEmail,
      password: password,
    };

    try {
      const apiResponse = await ServiceProxy.AuthenticatePost(request);
      if (apiResponse.success!) {
        //Local Storage Set ediliyor
        SetSuccessTokenToStorage(
          apiResponse.body!.tokenType!,
          apiResponse.body!.accessToken!,
          apiResponse.body!.expiresIn!,
          apiResponse.body!.refreshToken!
        );

        //Dönüş nesnesi oluşturuyor
        const response: IAuthenticateResponse = {
          loggedIn: true,
          messageCode: apiResponse.messageCode!,
          message: apiResponse.message!,
        };
        return response;
      } else {
        //Giriş başarılı değil. Dönüş nesnesi oluşturuyor
        const response: IAuthenticateResponse = {
          loggedIn: false,
          messageCode: apiResponse.messageCode!,
          message: apiResponse.message!,
        };
        return response;
      }
    } catch (err: any) {
      if (err!.response!) {
        const res = err.response!.data;

        const response: IAuthenticateResponse = {
          loggedIn: false,
          messageCode: res.messageCode!,
          message: res.message!,
        };
        return response;
      } else {
        //internet bağlantısında sorun olması durumunda
        const response: IAuthenticateResponse = {
          loggedIn: false,
          messageCode: "ConnectionProblem",
          message: "Bağlantı Hatası",
        };
        return response;
      }
    }
  }

  /** Refresh Token ile access token alır */
  static async AuthenticateRefreshToken(): Promise<IAuthenticateResponse> {
    //Refresh token getiriliyor.
    try {
      const refreshToken = GetAuthenticateStoreValue(
        AuthenticateStoreKeyEnum.RefreshToken
      );

      if (StringOps.isNullOrEmpty(refreshToken)) {
        //kayıtlı refresh token yok
        const response: IAuthenticateResponse = {
          loggedIn: false,
          messageCode: "NotFoundRefreshToken",
          message: "Daha önce giriş yapılmamış. Refresh token bulunamadı",
        };
        return response;
      }

      const request: dTokenReq = {
        grantType: "refresh_token",
        clientId: ConfigHelper.GetConfig(ConfigTypeEnum.ClientId)!, //Uygulama özelinde verilen değer
        secretKey: ConfigHelper.GetConfig(ConfigTypeEnum.ClientSecret)!, //Uygulama özelinde verilen şifre
        refreshToken: refreshToken,
        usernameEmail: "",
        password: "",
      };

      const apiResponse = await ServiceProxy.AuthenticatePost(request);

      if (apiResponse.success!) {
        //Giriş Başarılı

        //Local Storage Set ediliyor
        SetSuccessTokenToStorage(
          apiResponse.body!.tokenType!,
          apiResponse.body!.accessToken!,
          apiResponse.body!.expiresIn!,
          apiResponse.body!.refreshToken!
        );

        //Dönüş nesnesi oluşturuyor
        const response: IAuthenticateResponse = {
          loggedIn: true,
          messageCode: apiResponse.messageCode!,
          message: apiResponse.message!,
        };
        return response;
      } else {
        //Giriş başarılı değil. Dönüş nesnesi oluşturuyor
        const response: IAuthenticateResponse = {
          loggedIn: false,
          messageCode: apiResponse.messageCode!,
          message: apiResponse.message!,
        };
        return response;
      }
    } catch (err) {
      throw err;
    }
  }

  static async AuthenticateLogout(): Promise<void> {
    await ServiceProxy.AuthenticateDelete("currentUser");
    RemoveAuthenticateStoreValue();
  }

  /** AccessToken Getirir. Eğer expire olursa boş gelir */
  static GetAuthenticationAccessToken(): string {
    const accesToken = GetAuthenticateStoreValue(
      AuthenticateStoreKeyEnum.AccessToken
    );
    return accesToken;
  }
}
