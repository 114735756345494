/**
 * .net core Dictionary tipinin benzeri
 */
export class Dictionary<T, V> {
  values: IDictionary[] = [];

  // constructor() {
  //   this.values = [new tempValue()];
  // }

  add<T, V>(key: T, value: V): void {
    this.values.push({ Key: key, Value: value });
  }

  get(key: T): V {
    return this.values.find((x) => x.Key === key)!.Value;
  }
}

export interface IDictionary {
  Key: any;
  Value: any;
}
