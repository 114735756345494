import { Authenticate } from "core-framework/auth/authenticate";
import { ConfigTypeEnum } from "core-framework/base/enums";
import { ConfigHelper } from "core-framework/utils/helpers/configHelper";

/** Servise erişim sınıfı */
export class ServiceRouter {
  /** Enviromenta göre authenticate endpoint adresini döner  */
  static getAuthenticateUrl(): string {
    let url = ConfigHelper.GetConfig(ConfigTypeEnum.APIUrl);
    if (url === null) return "";

    return url + "AUT/Tokens";
  }

  /** Enviromenta göre api endpoint adresini döner  */
  static getServiceUrl(operationName: string): string {
    let url = ConfigHelper.GetConfig(ConfigTypeEnum.APIUrl);
    if (url === null) return "";

    return url + operationName;
  }

  /** Enviromenta göre api endpoint adresini döner  */
  static getWebSocketUrl(socketName: string): string {
    let url = ConfigHelper.GetConfig(ConfigTypeEnum.APIUrl);
    if (url === null) return "";

    return url + "hubs/" + socketName;
  }

  /** istekte bulunurken Axios Config bilgisini döner */
  static getRequestApiHeader(
    withAuth: boolean,
    screenName: string = "",
    responseType: string
  ): any {
    if (withAuth) {
      return {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: Authenticate.GetAuthenticationAccessToken(),
          ScreenName: screenName,
        },
        responseType: responseType,
      };
    } else {
      return {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        responseType: responseType,
      };
    }
  }
}
