//Değer atamasına başına reducer ismini yaz
//yoksa tüm reducerlara gidiyor
export enum AuthActionTypeEnum {
  Init = "@@INIT",
  SignInSuccess = "authSignInSuccess",
  SignInError = "authSignInError",
  SignOut = "authSignOut",
}

export interface IAuthAction {
  type: AuthActionTypeEnum;
  payload: IAuthState;
}

export enum AuthRoleEnum {
  NonMember = 1,
  User = 2,
  TenantAdmin = 4,
  SystemAdmin = 8,
}

export interface AuthKullanici {
  guid?: string;
  kullaniciKodu?: string;
  tamIsmi?: string;
  ad?: string;
  soyad?: string;
  telefon?: string;
  ePosta?: string;
  firmaGuid?: string;
  firmaUnvani?: string;
  rol?: AuthRoleEnum;
}

export interface IAuthState {
  loggedIn?: boolean;
  kullanici?: AuthKullanici;
  messageCode?: string;
  message?: string;
}

export const authInitialState: IAuthState = {
  loggedIn: false,
  kullanici: undefined,
  messageCode: undefined,
  message: undefined,
};
